<script setup>
import {computed} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import useAuthStore from "@/store/authStore";

// const showMenu = ref(false);
const store = useStore();
const authStore = useAuthStore();
const isRTL = computed(() => store.state.config.isRTL);
const isNavFixed = computed(() => store.state.config.isNavFixed);
const darkMode = computed(() => store.state.config.darkMode);

const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});

const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const user = computed(() => JSON.parse(localStorage.getItem("user")));

const navbarMinimize = () => store.commit("navbarMinimize");

const logOut = () => {
  authStore.dispatch("logout");
};

</script>
<template>
  <nav
id="navbarBlur" :class="`${!isNavFixed
      ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
      : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${darkMode ? 'bg-default' : 'bg-white'
      } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block  d-none text-black" :class="isRTL ? 'me-3' : ''">
      </div>
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
        </div>
        <ul class="navbar-nav justify-content-start me-4">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-black" @click.prevent="navbarMinimize">
              <div class="sidenav-toggler-inner">
                <i
:class="`sidenav-toggler-line opacity-8 bg-dark'
                  }`"></i>
                <i
:class="`sidenav-toggler-line opacity-8 bg-dark'
                  }`"></i>
                <i
:class="`sidenav-toggler-line opacity-8 bg-dark'
                  }`"></i>
              </div>
            </a>
          </li>
        </ul>
        <div class="dropdown me-lg-5"  >
          <a   data-bs-toggle="dropdown" data-bs-auto-close="outside" class="dropdown-toggle p-0 nav-link cursor-pointer d-flex flex-row align-items-center justify-content-center gap-2">
            <p class="text-start mb-0 text-black text-sm text-bold">{{ user?.nome ? user.nome : '' }}</p>
            <i v-if="!user?.profile" :class="`cursor-pointer fa fa-user fixed-plugin-button-nav opacity-8 text-dark'}`"></i>
            <img v-else :src="user?.profile" class="avatar avatar-sm text-dark" alt="Foto de perfil" />
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end" :style="'margin-top: 5px !important;'" >
            <a class="dropdown-item">
            <router-link  :to="{name: 'Configuracao'}">
              <i class="fa fa-cog me-2 text-primary"></i> Configuração
            </router-link>
            </a>
            <a class="dropdown-item">
              <router-link  :to="{name: 'Empresas'}">
                <i class="fa fa-random me-2 text-primary"></i> Alterar empresa
              </router-link>
            </a>
            <a class="dropdown-item" href="#" @click="logOut">
              <i class="fas fa-power-off me-2 text-primary"></i>Sair
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
