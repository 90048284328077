<script setup>
import {onMounted, ref} from "vue";
import {toast} from "vue3-toastify";
import useDebtsStore from "../../../store/DebtsStore";
import Loading from "@/components/Loading.vue";

const store = useDebtsStore();

const debts = ref([]);
const error = ref("");

const loadingData = ref(true);

const lastCopied = ref('');

onMounted(async () => {
  try {
    const myDebtsPromise = store.dispatch("getData");

    await Promise.all([
      myDebtsPromise.then(() => {
        debts.value = store.state.myDebts;
        error.value = store.state.error;
        loadingData.value = false;
      }),
    ]);
  } catch (error) {
    console.error("Erro:", error);
    loadingData.value = false;
  }
});

function expired(date) {
  let today = new Date();

  const parts = date?.split("/");
  if (parts) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    let docDate = new Date(year, month, day);
    return docDate <= today;
  }
  return false
}

function share(barCode) {
  if (navigator.share) {
    navigator
        .share({
          text: barCode,
        })
        .then(() => {
          toast.success("Compartilhado com sucesso!");
        })
        .catch(console.error);
  }
}

function copy(text) {
  if (text != lastCopied.value) {
    navigator.clipboard
        .writeText(text)
        .then(function () {
          toast.success("Copiado para área de transferência!");
        })
        .catch(function (err) {
          console.error("Erro ao copiar para a área de transferência: ", err);
        });
    lastCopied.value = text
    setTimeout(() => {
      lastCopied.value = ''
    }, 3000);
  }
}
</script>

<template>
  <div class="container">
    <div class="box">
      <section id="faq-search-filter" style="margin-top: 15px" class="mb-4">
        <div class="card faq-search">
          <div class="card-body text-center">
            <!-- main title -->
            <h2 class="">Meus Débitos</h2>
            <p class="card-text mb-2">
              Faça o pagamento no prazo e evite bloqueio.
            </p>
          </div>
        </div>
      </section>
      <Loading v-if="loadingData"/>

      <div
          v-if="!debts?.length && !loadingData && !error"
          class="text-center bg-light-success p-4 mt-2 rounded"
          role="alert"
      >
        Sem Débitos pendentes.
      </div>
      <div
          v-else-if="error"
          class="text-center bg-danger p-4 mt-2 rounded text-white"
          role="alert"
      >
        {{ error }}
      </div>

      <div
          v-for="(debt, index) in debts"
          v-else
          :key="index"
          class="card mb-3"
      >
        <div class="card-body text-center">
          <div style="font-size: 7vw; text-align: center">
            {{ `R$ ${debt.docValue}` }}
          </div>
          <div style="margin-bottom: 10px">
            <span>VENCIMENTO: </span>
            <span
                v-if="expired(debt.ticketExpiration)"
                class="text-danger"
            >{{ debt?.ticketExpiration }}</span
            >
            <span
                v-else
                style="padding: 5px 5px 5px 5px"
                class="text-warning"
            >{{ debt?.ticketExpiration }}</span
            >
          </div>
          <hr/>

          <p class="font-weight-bold">Selecione o método de pagamento:</p>
          <div class="row" v-if="debt?.pixQrCode && debt.pixQrCode != '' && debt.pixQrCode != ' '">
            <div class="col-lg-8 col-12 mx-auto">
            <span style="cursor: pointer; background-color: #32BCAD; color: #fff "
                  class="rounded-pill px-3 py-1 d-flex flex-row align-items-center justify-content-center "
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse-pix-${index}`"
                  aria-expanded="true"
                  aria-controls="collapse">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                   style="width: 16px; height: 16px; fill: currentColor; margin-right: .5rem">
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z"/>
              </svg>
              <p class="ml-2 m-0">Chave PIX</p>
          </span>
            </div>
          </div>

          <div :id="`collapse-pix-${index}`"
               class="text-center collapse">
            <small>Clique para copiar a chave copia e cola</small>
            <div class="card no-radius" @click="copy(debt.pixQrCode)">
            <textarea
                id="codbarecho"
                v-model="debt.pixQrCode"
                readonly
                name="Text1"
                class="text-center no-resize col-lg-8 col-12 mx-auto"
                rows="2"
            ></textarea>
            </div>
            <div class="row">
              <div class="col-lg-8 col-12 mx-auto">
                <a
                    class="btn btn-primary btn-block text-white"
                    @click="share(debt.pixQrCode)"
                >
                  <i class="fa fa-share-alt" aria-hidden="true"></i> Compartilhar
                </a>
              </div>
            </div>
            <hr/>
          </div>

          <div class=" col-lg-8 col-12 mt-1 mx-auto" v-if="debt?.barCode && debt.barCode != '' && debt.barCode != ' '">
            <span style="cursor: pointer; color: #fff"
                  class="bg-segment-primary-color rounded-pill px-3 py-1 d-flex flex-row align-items-center justify-content-center "
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse-boleto-${index}`"
                  aria-expanded="true"
                  aria-controls="collapse">
            <i class="fa fa-barcode me-1" aria-hidden="true"></i>
            <p class="m-0">Boleto bancário</p>
            </span>

          </div>

          <div :id="`collapse-boleto-${index}`"
               class="text-center collapse">
            <small>Clique para copiar a linha digitável</small>
            <div class="card no-radius" @click="copy(debt.barCode)">
    <textarea
        id="codbarecho"
        v-model="debt.barCode"
        readonly
        name="Text1"
        class="text-center no-resize col-lg-8 col-12 mx-auto"
        rows="2"
    ></textarea>
            </div>
            <div class="row">
              <div class="col-lg-8 col-12 mx-auto">
                <a
                    class="btn btn-primary btn-block text-white"
                    @click="share(debt.barCode)"
                >
                  <i class="fa fa-share-alt" aria-hidden="true"></i> Compartilhar
                </a>
              </div>
            </div>
            <hr/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.container {
  flex: 1;
}

.box {
  width: 100%;
}

.bg-gray {
  background-color: #cccccc;
}

.bg-light-success {
  color: white;
  background-color: #45c950;
}

.no-radius {
  border-radius: 0 !important;
}

.no-resize {
  resize: none;
}
</style>