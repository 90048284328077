<script setup>
import { onBeforeMount, onMounted, ref } from "vue";
import useCampaignsStore from "../../../store/CampaignsStore";
import useOldCampaignsStore from "../../../store/OldCampaignsStore";
import CampaingCard from "./CampaingCard.vue";
import OldCampaingCard from "./OldCampaingCard.vue";

const store = useCampaignsStore();
const oldCampaignsStore = useOldCampaignsStore();

const campaignsLoading = ref(true);
const oldCampaignsLoading = ref(true);
const bannersLoading = ref(true);
const filteredCampaigns = ref([]);
const filteredOldCampaigns = ref([]);
const bannerImages = ref([]);

onBeforeMount(() => {
  const script = document.getElementsByClassName("g-recaptcha")[0];
  if (script) {
    document.head.removeChild(script);
  }
});

onMounted(async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const flexibleClient = user ? user.codcli : null;

    if (flexibleClient) {
      const bannersPromise = store.dispatch("getBanner", {
        flexibleClient: flexibleClient,
      });

      bannersPromise.then(() => {
        bannerImages.value = store.state.bannerImages;
        bannersLoading.value = false;
      });

      const campaignsPromise = store.dispatch("getCampaigns", {
        flexibleClient: flexibleClient,
      });

      const oldCampaignsPromise = oldCampaignsStore.dispatch("getOldCampaigns");

      await Promise.all([
        oldCampaignsPromise.then(() => {
          filteredOldCampaigns.value = oldCampaignsStore.state.oldCampaigns;
          oldCampaignsLoading.value = false;
        }),
        campaignsPromise.then(() => {
          filteredCampaigns.value = store.state.campaigns;
          campaignsLoading.value = false;
        }),
      ]);
    } else {
      throw new Error("Usuário não encontrado no localStorage");
    }
  } catch (error) {
    console.error("Erro:", error);
    campaignsLoading.value = false;
    bannersLoading.value = false;
  }
});
</script>
<template>
  <div class="container">
    <div class="content orders-container">
      <div class="orders-row">
        <div class="orders-col">
          <div class="orders-card">
            <div class="orders-card-body">
              <section class="orders-section bg-transparent">
                <section v-if="!bannersLoading" class="m-0">
                  <div class="row">
                    <div class="col-md-8 m-0 w-100">
                      <div
                        id="carouselExampleIndicators"
                        class="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <ol class="carousel-indicators">
                          <li
                            v-for="(image, index) in bannerImages"
                            :key="image.id"
                            data-target="#carouselExampleIndicators"
                            :data-bs-slide-to="index"
                            :class="index == 0 ? 'active' : 'nonActive'"
                          ></li>
                        </ol>
                        <div class="carousel-inner">
                          <div
                            v-for="(image, index) in bannerImages"
                            :key="image.id"
                            :class="[
                              'carousel-item',
                              index === 0 ? 'active' : '',
                            ]"
                          >
                            <img
                              class="d-block w-100 carousel-img"
                              :src="image.image_url"
                              alt="Banner image"
                            />
                          </div>
                        </div>
                        <a
                          class="carousel-control-prev"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselExampleIndicators"
                          role="button"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
                <p v-else class="placeholder-glow mb-0" style="{color:#344767}">
                  <span class="placeholder col-12 place-banner"></span>
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="content orders-container">
      <div class="orders-row">
        <div class="orders-col">
          <div class="orders-card">
            <!-- Card header -->
            <div class="orders-card-header">
              <div class="orders-header-content">
                <h5 class="orders-title">Campanhas</h5>
                <p class="orders-description">
                  Participe de Campanhas Conecta Brasal para ganhar muitos benefícios!
                </p>
              </div>
            </div>
            <div class="orders-card-body">
              <section class="orders-section px-2 pb-3">
                <h3
                  v-if="
                    !campaignsLoading &&
                    !oldCampaignsLoading &&
                    filteredCampaigns?.length === 0 &&
                    filteredOldCampaigns?.length === 0
                  "
                  class="orders-no-products py-4"
                >
                  Você não está participando de nenhuma campanha no momento!
                </h3>
                <div
                  v-else-if="
                    (campaignsLoading && oldCampaignsLoading) ||
                    (campaignsLoading && filteredOldCampaigns?.length === 0) ||
                    (oldCampaignsLoading && filteredCampaigns?.length === 0)
                  "
                  class="orders-campaign-container"
                >
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else-if="campaignsLoading || oldCampaignsLoading">
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0 mt-0">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder col-12 place-image"></span>
                      </p>
                    </div>
                    <div class="card-body py-3">
                      <p class="placeholder-glow mb-0" style="{color:#344767}">
                        <span class="placeholder placeholder-lg col-12"></span>
                      </p>
                      <p
                        class="placeholder-glow mb-0 mt-3 d-flex flex-column"
                        style="{color:#344767}"
                      >
                        <span class="placeholder col-3"></span>
                        <span class="placeholder col-8 mt-1"></span>
                      </p>
                    </div>
                    <div class="card-footer p-1">
                      <p
                        class="placeholder-glow mb-1 mt-2"
                        style="{color:#344767}"
                      >
                        <span
                          class="placeholder placeholder-lg col-12 border-radius-sm place-button"
                        ></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else class="orders-campaign-container">
                  <CampaingCard
                    v-for="campaign in filteredCampaigns"
                    :id="campaign.campaign.id"
                    :key="campaign.campaign.id"
                    :banner_data="campaign.campaign.banner_data"
                    :name="campaign.campaign.name"
                    :initial-date="campaign.campaign.initial_date"
                    :final-date="campaign.campaign.final_date"
                    :term-status="campaign.term_status"
                  />
                  <OldCampaingCard
                    v-for="oldCampaign in filteredOldCampaigns"
                    :id="oldCampaign.idcampanha"
                    :key="oldCampaign.idcampanha"
                    :banner_data="oldCampaign.imagem"
                    :term-status="oldCampaign.participando"
                    :final-date="oldCampaign.dt_fim_insc"
                    :initial-date="oldCampaign.dt_ini_insc"
                    :name="oldCampaign.nome"
                    :subtitle="oldCampaign.desc_curta"
                    :type="oldCampaign.tipo"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<style>
.container {
  display: flex;
}

.content {
  flex: 1;
}

.orders-container {
  padding-top: 1rem;
}

.orders-row {
  display: flex;
  flex-direction: row;
}

.orders-col {
  flex: 1;
}

.orders-card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0;
}

.orders-card-header {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.orders-header-content {
  flex: 1;
}

.orders-title {
  margin: 0;
  font-size: 1.25rem;
}

.orders-description {
  margin: 0;
  font-size: 0.875rem;
}

.orders-header-actions {
  flex-shrink: 0;
}

.orders-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
}

.orders-card-body {
  padding: 0;
}

.orders-section {
  border-radius: 0.5rem;
  background-color: #f3f4f6;
  padding: 0 !important;
}

.orders-campaign-container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
}

@media screen and (max-width: 1445px) {
  .orders-campaign-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .sidebar {
    width: 150px;
  }

  .category-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 550px) {
  .orders-campaign-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.orders-no-products {
  text-align: center;
  margin-top: 1rem;
}

.carousel-inner {
  border-radius: 0.5rem;
  width: 100%;
  max-height: 500px;
}

.place-banner {
  height: 180px;
  border-radius: 1rem;
}

@media (max-width: 1024px) {
  .place-banner {
    height: 450px;
  }
  .place-image {
    height: 360px;
  }
  .place-button {
    height: 43px;
  }
}

@media (min-width: 1025px) {
  .place-banner {
    height: 525px;
  }
  .place-image {
    height: 360px;
  }
  .place-button {
    height: 43px;
  }
}

@media (max-width: 500px) {
  .place-banner {
    height: 180px;
    border-radius: 1rem;
  }

  .place-image {
    height: 205px;
  }
  .place-button {
    height: 40px;
  }
}

.carousel-inner {
  border-radius: 0.5rem;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

@media (max-width: 1024px) {
  .carousel-inner {
    height: 400px;
  }
}

@media (max-width: 750px) {
  .carousel-inner {
    height: 300px;
  }
}

@media (max-width: 500px) {
  .carousel-inner {
    height: 200px;
  }
}
</style>
