<script setup>
import {computed, onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import useProductStore from "../../../../store/ProductsStore";
import Product from "./Product.vue";

import Loading from "@/components/Loading.vue";
import router from "@/router";
import {useOneSignal} from "@onesignal/onesignal-vue3";
import axios from "@/axios";

const store = useProductStore();
const filteredProducts = ref([]);
const filteredPromotions = ref([]);
const filteredCombos = ref([]);
const filteredRecents = ref([]);
const segmentations = ref([]);
const clientData = ref({});
const toggleSideBar = () => {
  const categoriesAbsolute = document.querySelector('.categories-absolute');
  categoriesAbsolute.style.display = categoriesAbsolute.style.display === 'block' ? 'none' : 'block';
};

const toggleSideBarBrand = () => {
  const brandsAbsolute = document.querySelector('.brands-absolute');
  brandsAbsolute.style.display = brandsAbsolute.style.display === 'block' ? 'none' : 'block';
};

const closeBothSideBars = () => {
  const categoriesAbsolute = document.querySelector('.categories-absolute');
  const brandsAbsolute = document.querySelector('.brands-absolute');

  categoriesAbsolute.style.display = 'none';
  brandsAbsolute.style.display = 'none';
};

const productsPagination = ref({});
const promotionsPagination = ref({});
const combosPagination = ref({});
const recentsPagination = ref({});

const loadingProducts = ref(true);
const loadingPromotions = ref(true);
const loadingCombos = ref(true);
const loadingRecents = ref(true);
const loadingCategories = ref(true);
const loadingBrands = ref(false)

const currentTab = ref("produtos");


const statusLastPurchase = ref('')


const selectedSegmentation = ref(
    localStorage.getItem("selectedSegmentation")
        ? localStorage.getItem("selectedSegmentation")
        : ""
);
const selectedBrand = ref(
    localStorage.getItem("selectedBrand")
        ? localStorage.getItem("selectedBrand")
        : ""
);

const cartItems = ref(
    localStorage.getItem("productsOnCart")
        ? JSON.parse(localStorage.getItem("productsOnCart"))
        : []
);

const totalItems = computed(() => {
  return cartItems.value.reduce((total, cartItem) => total + cartItem.total, 0);
})

onBeforeMount(async () => {
  const one = useOneSignal();
  console.log(one)
  if (one.User.PushSubscription.id) {
    await sendSubscriptionIdToBackend(one.User.PushSubscription.id)
  }
})

const sendSubscriptionIdToBackend = async (playerId) => {
  await axios.put('/client/push', {playerId})
}

onMounted(async () => {
  const sidebarContainer = document.querySelector('.sidebar');
  const sidebarLine = document.querySelector('.filter-line');
  const sidebarBrandLine = document.querySelector('.filter-line-brand');
  const sidebarContainerPosition = sidebarContainer.getBoundingClientRect().bottom + window.pageYOffset;

  function toggleFilterLines() {
    const shouldDisplay = window.pageYOffset > sidebarContainerPosition && window.innerWidth <= 1024;
    const filterLineToDisplay = selectedSegmentation.value ? sidebarBrandLine : sidebarLine;
    const categoriesAbsolute = document.querySelector('.categories-absolute');
    const brandsAbsolute = document.querySelector('.brands-absolute');

    if (filterLineToDisplay && categoriesAbsolute && brandsAbsolute) {
      if (shouldDisplay) {
        filterLineToDisplay.style.display = 'block';
      } else {
        filterLineToDisplay.style.display = 'none';
        categoriesAbsolute.style.display = 'none';
        brandsAbsolute.style.display = 'none';
      }
    }
  }

  window.addEventListener('scroll', toggleFilterLines);
  try {
    const lastPurchasePromise = store.dispatch('getLastPurchaseDate');
    const productsPromise = store.dispatch("getProducts", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
      category: selectedSegmentation.value ? selectedSegmentation.value : "",
      brand: selectedBrand.value ? selectedBrand.value : "",
      perPage: 24,
      currentPage: 1,
    });

    const promotionsPromise = store.dispatch("getPromotions", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
      perPage: 24,
      currentPage: 1,
    });

    const combosPromise = store.dispatch("getCombos", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
      perPage: 24,
      currentPage: 1,
    });

    const recentsPromise = store.dispatch("getRecents", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
      perPage: 24,
      currentPage: 1,
    });

    const segmentationsPromise = store.dispatch("getSegmentations", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
    });

    const clientDataPromise = store.dispatch("getClientData", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
    });

    await Promise.all([
      lastPurchasePromise.then((date) => {
        statusLastPurchase.value = date;
      }),
      productsPromise.then(() => {
        filteredProducts.value = store.state.products;
        loadingProducts.value = false;
        productsPagination.value = store.state.productsPagination;
        if (selectedSegmentation.value) {
          loadingCategories.value = false;
        }
      }),
      promotionsPromise.then(() => {
        filteredPromotions.value = store.state.promotions;
        loadingPromotions.value = false;
        promotionsPagination.value = store.state.promotionsPagination;
      }),
      combosPromise.then(() => {
        filteredCombos.value = store.state.combos;
        loadingCombos.value = false;
        combosPagination.value = store.state.combosPagination;
      }),
      recentsPromise.then(() => {
        filteredRecents.value = store.state.recents;
        loadingRecents.value = false;
        recentsPagination.value = store.state.recentsPagination;
      }),
      segmentationsPromise.then(() => {
        segmentations.value = store.state.segmentations;
        if (!selectedSegmentation.value) {
          loadingCategories.value = false;
        }
      }),
      clientDataPromise.then(() => {
        clientData.value = store.state.clientData;
      }),
    ]);
  } catch (error) {
    console.error("Erro:", error);
    loadingProducts.value = false;
    loadingPromotions.value = false;
    loadingCombos.value = false;
    loadingCategories.value = false;
  }
});

onBeforeUnmount(() => {
      const sidebarContainer = document.querySelector('.sidebar');
      const sidebarLine = document.querySelector('.filter-line');
      const sidebarBrandLine = document.querySelector('.filter-line-brand');
      const sidebarContainerPosition = sidebarContainer?.getBoundingClientRect()?.bottom + window.pageYOffset;
       if (!sidebarContainerPosition) return;

      function toggleFilterLines() {
        const shouldDisplay = window.pageYOffset > sidebarContainerPosition && window.innerWidth <= 1024;
        const filterLineToDisplay = selectedSegmentation.value ? sidebarBrandLine : sidebarLine;
        const categoriesAbsolute = document.querySelector('.categories-absolute');
        const brandsAbsolute = document.querySelector('.brands-absolute');

        if (filterLineToDisplay && categoriesAbsolute && brandsAbsolute) {
          if (shouldDisplay) {
            filterLineToDisplay.style.display = 'block';
          } else {
            filterLineToDisplay.style.display = 'none';
            categoriesAbsolute.style.display = 'none';
            brandsAbsolute.style.display = 'none';
          }
        }
      }

      window.removeEventListener('scroll', toggleFilterLines)
    }
)


async function getProducts(currentPage) {
  try {
    loadingProducts.value = true;
    await store
        .dispatch("getProducts", {
          flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
          category: selectedSegmentation.value ? selectedSegmentation.value : "",
          brand: selectedBrand.value ? selectedBrand.value : "",
          perPage: 24,
          currentPage,
        })
        .then(() => {
          filteredProducts.value = store.state.products;
          loadingProducts.value = false;
          productsPagination.value = store.state.productsPagination;
          if (selectedSegmentation.value) {
            loadingCategories.value = false;
          }
        });
  } catch (error) {
    console.error("Erro:", error);
    loadingProducts.value = false;
  }
}

async function getPromotions(currentPage) {
  try {
    loadingPromotions.value = true;
    await store
        .dispatch("getPromotions", {
          flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
          perPage: 24,
          currentPage: currentPage,
        })
        .then(() => {
          filteredPromotions.value = store.state.promotions;
          loadingPromotions.value = false;
          promotionsPagination.value = store.state.promotionsPagination;

        });
  } catch (error) {
    console.error("Erro:", error);
    loadingPromotions.value = false;
  }
}

async function getCombos(currentPage) {
  try {
    loadingCombos.value = true;
    await store
        .dispatch("getCombos", {
          flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
          perPage: 24,
          currentPage: currentPage,
        })
        .then(() => {
          filteredCombos.value = store.state.combos;
          loadingCombos.value = false;
          combosPagination.value = store.state.combosPagination;
        });
  } catch (error) {
    console.error("Erro:", error);
    loadingCombos.value = false;
  }
}

async function getRecents(currentPage) {
  try {
    loadingRecents.value = true;
    await store
        .dispatch("getRecents", {
          flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
          perPage: 24,
          currentPage: currentPage,
        })
        .then(() => {
          filteredRecents.value = store.state.promotions;
          loadingRecents.value = false;
          recentsPagination.value = store.state.recentsPagination;
        });
  } catch (error) {
    console.error("Erro:", error);
    loadingRecents.value = false;
  }
}

async function selectSegmentation(segmentation) {
  closeBothSideBars();
  loadingProducts.value = true;
  loadingCategories.value = true;
  loadingBrands.value = true;
  window.scrollTo(0, 0);

  localStorage.setItem("selectedSegmentation", segmentation);
  localStorage.setItem("selectedBrand", "");

  await store.dispatch("getProducts", {
    flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
    category: segmentation,
    brand: "",
    perPage: 24,
    currentPage: 1,
  });

  selectedSegmentation.value = segmentation;
  filteredProducts.value = store.state.products;
  loadingProducts.value = false;
  loadingCategories.value = false;
  loadingBrands.value = false;

  productsPagination.value = store.state.productsPagination;
}

async function selectBrand(brand) {
  closeBothSideBars();
  loadingProducts.value = true;
  loadingCategories.value = true;
  loadingBrands.value = true;
  window.scrollTo(0, 0);


  localStorage.setItem("selectedBrand", brand);

  await store.dispatch("getProducts", {
    flexibleClient: JSON.parse(localStorage.getItem("user")).codcli,
    category: localStorage.getItem("selectedSegmentation"),
    brand: brand,
    perPage: 24,
    currentPage: 1,
  });

  selectedBrand.value = brand;
  filteredProducts.value = store.state.products;
  loadingProducts.value = false;
  loadingCategories.value = false;
  loadingBrands.value = false;
}

async function addToCart(sku, total) {
  await store.dispatch("addToCart", {
    sku: sku,
    total: total,
  });
  cartItems.value = localStorage.getItem("productsOnCart")
      ? JSON.parse(localStorage.getItem("productsOnCart"))
      : [];
}

function getProductTotal(product) {
  const cartItem = cartItems?.value?.find((item) => item.sku == product.sku);
  return cartItem ? cartItem.total : 0;
}


</script>

<template>
  <div class="container">
    <div class="content orders-container">
      <div class="pt-0 tabs-container mb-lg-3">
        <div v-if="totalItems > 0"
             class="cart bg-gray-400 text-black text-center w-100 py-1 px-2 d-flex flex-row align-items-center justify-content-between"
             @click="() => router.push('/pedidos/carrinho')">

          <p class="m-0 font-weight-bold ">
            Ver Carrinho
            <i class="fa fa-chevron-right ms-2 mt-2" aria-hidden="true"></i>

          </p>
          <p class="m-0">
            {{ totalItems }} {{ totalItems > 1 ? 'itens' : 'item' }}
          </p>

        </div>
        <div class="teste">

          <a id="products-tab-toggler" href="#" @click="currentTab = 'produtos'">
            <div class="tab-item text-center">
              <img
                  class="tab-icon"
                  src="../../../../../public/img/pedidos/products.png"
                  alt="Produtos"
              />
              <label class="text-sm mt-2" :class="currentTab == 'produtos' ? 'text-danger' : ''"><b>Produtos</b></label>
            </div>
          </a>
          <a id="promotions-tab-toggler" href="#" @click="currentTab = 'promoções'">
            <div class="tab-item text-center">
              <img
                  class="tab-icon"
                  src="../../../../../public/img/pedidos/history.png"
                  alt="Novidades"
              />
              <label class="text-sm mt-2" :class="currentTab == 'promoções' ? 'text-danger' : ''"
              ><b>Novidades</b></label
              >
            </div>
          </a>
          <a id="combos-tab-toggler" href="#" @click="currentTab = 'combos'">
            <div class="tab-item text-center">
              <img
                  class="tab-icon"
                  src="../../../../../public/img/pedidos/combos.png"
                  alt="Combos"
              />
              <label class="text-sm mt-2" :class="currentTab == 'combos' ? 'text-danger' : ''"><b>Combos</b></label>
            </div>
          </a>
          <a id="favorites-tab-toggler" href="#" @click="currentTab = 'recentes'">
            <div class="tab-item text-center">
              <img
                  class="tab-icon"
                  src="../../../../../public/img/pedidos/favorites.png"
                  alt="Favoritos"
              />
              <label class="text-sm mt-2"
                     :class="currentTab == 'recentes' ? 'text-danger' : ''"><b>Favoritos</b></label>
            </div>
          </a>


        </div>
      </div>

      <div class="orders-row">
        <div v-if="currentTab === 'produtos'" :style="{display: 'none'}"
             class="filter-line text-bold text-black text-center" @click="toggleSideBar">
          <span>Filtre por categoria</span>
          <i class="fa fa-filter ms-2" aria-hidden="true"></i>

        </div>
        <div
            v-if="currentTab === 'produtos'"
            :style="{display: 'none'}" class="filter-line-brand text-bold text-black text-center"
            @click="toggleSideBarBrand">
          <span>Filtre por marca</span>
          <i class="fa fa-filter ms-2" aria-hidden="true"></i>
        </div>
        <div v-if="currentTab === 'produtos'" :style="{display: 'none'}" class="categories-absolute">
          <div class="d-flex flex-row align-items-center justify-content-end">
            <button
                class="btn btn-primary export w-10 p-2 mb-0 d-flex align-items-center justify-content-center"
                @click="toggleSideBar"
            >
              <i class="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
          <ul v-if="!loadingCategories" class="category-grid">
            <li
                v-for="segmentation in store.state.segmentations"
                :key="segmentation.name"
                class="category-item"
                @click="selectSegmentation(segmentation.name)"
            >
              <img :src="segmentation.image_url" :alt="segmentation.name"/>
            </li>
          </ul>
          <ul v-else class="category-grid p-2">
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>

          </ul>
        </div>
        <div v-if="currentTab === 'produtos'" :style="{display: 'none'}" class="brands-absolute">
          <div class="d-flex flex-row-reverse align-items-center justify-content-between ">
            <button
                class="btn btn-primary export w-10 d-flex p-2 mb-0 align-items-center  justify-content-center"
                @click="toggleSideBarBrand"
            >
              <i class="fa fa-close" aria-hidden="true"></i>
            </button>
            <button
                class="btn btn-primary w-30 text-nowrap p-1 mb-0"
                @click="selectSegmentation('')"
            >
              <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i>
              Voltar
            </button>
          </div>
          <ul v-if="loadingBrands || loadingCategories" class="category-grid p-2">
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
            <li class="category-item">
              <p class="placeholder-glow mb-0">
                <span class="placeholder col-12 place-category"></span>
              </p>
            </li>
          </ul>
          <ul v-else class="category-grid">
            <li
                v-for="brand in store.state.brands"
                :key="brand.name"
                class="category-item"
                @click="selectBrand(brand.name)"
            >
              <img :src="brand.image_url" :alt="brand?.name"/>
            </li>
          </ul>
        </div>
        <div v-if="currentTab === 'produtos'" class="sidebar">

          <div v-if="!selectedSegmentation">
            <!-- Sidebar content (filtro de categorias) -->
            <ul v-if="!loadingCategories" class="category-grid">
              <li
                  v-for="segmentation in store.state.segmentations"
                  :key="segmentation.name"
                  class="category-item"
                  @click="selectSegmentation(segmentation.name)"
              >
                <img :src="segmentation.image_url" :alt="segmentation.name"/>
              </li>
            </ul>
            <ul v-else class="category-grid p-2">
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>

            </ul>
          </div>
          <div v-else-if="selectedSegmentation">
            <div class="d-flex flex-row align-items-center justify-content-start">
              <button
                  class="btn btn-primary export w-30 ms-2 mt-2 p-1 mb-0 text-nowrap "
                  @click="selectSegmentation('')"
              >
                <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i>
                Voltar
              </button>
            </div>
            <ul v-if="loadingBrands || loadingCategories" class="category-grid p-2">
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
              <li class="category-item">
                <p class="placeholder-glow mb-0">
                  <span class="placeholder col-12 place-category"></span>
                </p>
              </li>
            </ul>
            <ul v-else class="category-grid">
              <li
                  v-for="brand in store.state.brands"
                  :key="brand.name"
                  class="category-item"
                  @click="selectBrand(brand.name)"
              >
                <img :src="brand.image_url" alt="Category Image"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="orders-col">
          <div class="orders-card">
            <div class="orders-card-header">
              <div class="orders-header-content">
                <h5 v-if="currentTab === 'produtos'" class="orders-title">
                  Produtos
                </h5>
                <h5 v-if="currentTab === 'promoções'" class="orders-title">
                  Promoções
                </h5>
                <h5 v-if="currentTab === 'combos'" class="orders-title">
                  Combos
                </h5>
                <h5 v-if="currentTab === 'recentes'" class="orders-title">
                  Recentes
                </h5>
                <p class="orders-description">
                  Compre aqui seus produtos Brasal Refrigerantes!
                </p>
              </div>
              <!--              <div class="orders-header-actions" >-->
              <!--                <Cart-->
              <!--                    :cart-items="cartItems"-->
              <!--                    :client-data="clientData"-->
              <!--                    :add-to-cart="addToCart"-->
              <!--                />-->
              <!--              </div>-->
            </div>

            <div class="orders-card-body">
              <section v-if="currentTab === 'produtos'" class="orders-section">
                <!--                <Loading v-if="loadingProducts" short loading-text="Buscando os produtos"-->
                <!--                         :style="{paddingTop: '1rem'}"/>-->
                <div v-if="loadingProducts" class="orders-product-container">
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>
                  <div class="card px-3 py-1 place-product">
                    <p class="placeholder-glow d-flex justify-content-center">
                      <span class="placeholder col-12 place-product-image"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-12"></span>
                    </p>
                    <p class="placeholder-glow d-flex justify-content-between ">
                      <span class="placeholder placeholder-lg col-4"></span>
                      <span class="placeholder col-4"></span>
                    </p>
                    <p class="placeholder-glow mb-0 mt-2 d-flex justify-content-between ">
                      <span class="placeholder col-4 place-button"></span>
                      <span class="placeholder col-7 place-button"></span>
                    </p>
                  </div>

                </div>
                <h1
                    v-show="!loadingProducts && filteredProducts?.length === 0"
                    class="orders-no-products p-5"
                >
                  Ops... Não encontramos nenhum produto.
                </h1>
                <div v-if="!loadingProducts" class="orders-product-container">
                  <Product
                      v-for="product in filteredProducts"
                      :id="product.sku"
                      :key="product.sku"
                      :sku="product.sku"
                      :product-name="`${product?.descricao} ${typeof(product?.complemento) == 'string' ? product?.complemento?.replace(' - R$', '') : product?.complemento}`"
                      :price="product.preco"
                      :stock="product.estoque"
                      :product-img="product.fotoProduto"
                      :add-to-cart="addToCart"
                      :is-on-cart="
                      cartItems.findIndex(
                        (item) => item.sku === product.sku
                      ) !== -1
                    "
                      :total-on-cart="getProductTotal(product)"
                      :faixa-volume2="product.faixaVolume2"
                      :faixa-volume3="product.faixaVolume3"
                      :faixa-volume4="product.faixaVolume4"
                      :faixa-volume5="product.faixaVolume5"
                      :kdkg2="product.kdkg2"
                      :kdkg3="product.kdkg3"
                      :kdkg4="product.kdkg4"
                      :kdkg5="product.kdkg5"
                  />
                </div>
                <section v-show="!loadingProducts" class="m-auto mt-4">
                  <div class="row">
                    <div class="col-sm-12">
                      <nav>
                        <ul class="pagination justify-content-center mt-2">
                          <li
                              v-if="productsPagination.currentPage != 1"
                              class="page-item prev-item"
                              @click="getProducts(productsPagination.currentPage - 1)"
                          >
                            <a class="page-link" href="#">{{ "<" }}</a>
                          </li>
                          <li
                              v-for="page in productsPagination.pages"
                              :key="page"
                              class="page-item"
                              @click="getProducts(page)"
                          >
                            <a
                                :class="`page-link ${page === productsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ page }}</a
                            >
                          </li>
                          <li
                              v-if="
                              productsPagination.lastPage >
                              productsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getProducts(productsPagination.lastPage - 1)"
                          >
                            <a
                                :class="`page-link ${productsPagination.lastPage === productsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >...</a
                            >
                          </li>
                          <li
                              v-if="
                              productsPagination.lastPage >
                              productsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getProducts(productsPagination.lastPage)"
                          >
                            <a
                                :class="`page-link ${productsPagination.lastPage === productsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ productsPagination.lastPage }}</a
                            >
                          </li>
                          <li
                              v-if="
                              productsPagination.currentPage !=
                              productsPagination.lastPage
                            "
                              class="page-item last-item"
                              @click="getProducts(productsPagination.currentPage + 1)"
                          >
                            <a class="page-link" href="#">{{ ">" }}</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </section>
              </section>
              <section v-if="currentTab === 'promoções'" class="orders-section">
                <Loading
                    v-if="loadingPromotions" short loading-text="Buscando as promoções"
                    :style="{paddingTop: '1rem'}"/>
                <!--                <h1 v-show="loadingPromotions" class="orders-no-products p-5">-->
                <!--                  Carregando...-->
                <!--                </h1>-->
                <h1
                    v-show="
                    !loadingPromotions && filteredPromotions?.length === 0
                  "
                    class="orders-no-products p-5"
                >
                  Ops... Não encontramos nenhum produto.
                </h1>
                <div class="orders-product-container">
                  <Product
                      v-for="product in filteredPromotions"
                      :id="product.sku"
                      :key="product.sku"
                      :sku="product.sku"
                      :product-name="`${product?.descricao} ${product?.complemento ? product?.complemento?.replace(' - R$', '') : 'batata doce'}`"
                      :price="product.preco"
                      :stock="product.estoque"
                      :product-img="product.fotoProduto"
                      :add-to-cart="addToCart"
                      :is-on-cart="
                      cartItems.findIndex(
                        (item) => item.sku === product.sku
                      ) !== -1
                    "
                      :total-on-cart="getProductTotal(product)"
                      :faixa-volume2="product.faixaVolume2"
                      :faixa-volume3="product.faixaVolume3"
                      :faixa-volume4="product.faixaVolume4"
                      :faixa-volume5="product.faixaVolume5"
                      :kdkg2="product.kdkg2"
                      :kdkg3="product.kdkg3"
                      :kdkg4="product.kdkg4"
                      :kdkg5="product.kdkg5"
                  />
                </div>
                <section v-show="!loadingPromotions" class="m-auto mt-4">
                  <div class="row">
                    <div class="col-sm-12">
                      <nav>
                        <ul class="pagination justify-content-center mt-2">
                          <li
                              v-if="promotionsPagination.currentPage != 1"
                              class="page-item prev-item"
                              @click="getPromotions(promotionsPagination.currentPage - 1)"
                          >
                            <a class="page-link" href="#">{{ "<" }}</a>
                          </li>
                          <li
                              v-for="page in promotionsPagination.pages"
                              :key="page"
                              class="page-item"
                              @click="getPromotions(page)"
                          >
                            <a
                                :class="`page-link ${page === promotionsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ page }}</a
                            >
                          </li>
                          <li
                              v-if="
                              promotionsPagination.lastPage >
                              promotionsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getPromotions(promotionsPagination.lastPage - 1)"
                          >
                            <a
                                :class="`page-link ${promotionsPagination.lastPage === promotionsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >...</a
                            >
                          </li>
                          <li
                              v-if="
                              promotionsPagination.lastPage >
                              promotionsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="
                              getPromotions(promotionsPagination.lastPage)
                            "
                          >
                            <a
                                :class="`page-link ${promotionsPagination.lastPage === promotionsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ promotionsPagination.lastPage }}</a
                            >
                          </li>
                          <li
                              v-if="
                              promotionsPagination.currentPage !=
                              promotionsPagination.lastPage
                            "
                              class="page-item last-item"
                              @click="
                              getPromotions(promotionsPagination.currentPage + 1)
                            "
                          >
                            <a class="page-link" href="#">{{ ">" }}</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </section>
              </section>
              <section v-if="currentTab === 'combos'" class="orders-section">
                <Loading v-if="loadingCombos" short loading-text="Buscando os combos" :style="{paddingTop: '1rem'}"/>
                <h1
                    v-show="!loadingCombos && filteredCombos?.length === 0"
                    class="orders-no-products p-5"
                >
                  Ops... Não encontramos nenhum produto.
                </h1>
                <div class="orders-product-container">
                  <Product
                      v-for="product in filteredCombos"
                      :id="product.sku"
                      :key="product.sku"
                      :sku="product.sku"
                      :product-name="product?.descricao"
                      :price="product.preco"
                      :stock="product.estoque"
                      :product-img="product.fotoProduto"
                      :add-to-cart="addToCart"
                      :is-on-cart="
                      cartItems.findIndex(
                        (item) => item.sku === product.sku
                      ) !== -1
                    "
                      :total-on-cart="getProductTotal(product)"
                      :faixa-volume2="product.faixaVolume2"
                      :faixa-volume3="product.faixaVolume3"
                      :faixa-volume4="product.faixaVolume4"
                      :faixa-volume5="product.faixaVolume5"
                      :kdkg2="product.kdkg2"
                      :kdkg3="product.kdkg3"
                      :kdkg4="product.kdkg4"
                      :kdkg5="product.kdkg5"
                  />
                </div>
                <section v-show="!loadingCombos" class="m-auto mt-4">
                  <div class="row">
                    <div class="col-sm-12">
                      <nav>
                        <ul class="pagination justify-content-center mt-2">
                          <li
                              v-if="combosPagination.currentPage != 1"
                              class="page-item prev-item"
                              @click="getCombos(combosPagination.currentPage - 1)"
                          >
                            <a class="page-link" href="#">{{ "<" }}</a>
                          </li>
                          <li
                              v-for="page in combosPagination.pages"
                              :key="page"
                              class="page-item"
                              @click="getCombos(page)"
                          >
                            <a
                                :class="`page-link ${page === combosPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ page }}</a
                            >
                          </li>
                          <li
                              v-if="
                              combosPagination.lastPage >
                              combosPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getCombos(combosPagination.lastPage - 1)"
                          >
                            <a
                                :class="`page-link ${combosPagination.lastPage === combosPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >...</a
                            >
                          </li>
                          <li
                              v-if="
                              combosPagination.lastPage >
                              combosPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getCombos(combosPagination.lastPage)"
                          >
                            <a
                                :class="`page-link ${combosPagination.lastPage === combosPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ combosPagination.lastPage }}</a
                            >
                          </li>
                          <li
                              v-if="
                              combosPagination.currentPage !=
                              combosPagination.lastPage
                            "
                              class="page-item last-item"
                              @click="getCombos(combosPagination.currentPage + 1)"
                          >
                            <a class="page-link" href="#">{{ ">" }}</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </section>
              </section>
              <section v-if="currentTab === 'recentes'" class="orders-section">
                <Loading
                    v-if="loadingRecents" short loading-text="Buscando os pedidos recentes"
                    :style="{paddingTop: '1rem'}"/>

                <h1
                    v-show="!loadingRecents && filteredRecents?.length === 0"
                    class="orders-no-products p-5"
                >
                  Ops... Não encontramos nenhum produto.
                </h1>
                <div class="orders-product-container">
                  <Product
                      v-for="product in filteredRecents"
                      :id="product.sku"
                      :key="product.sku"
                      :sku="product.sku"
                      :product-name="product?.descricao"
                      :price="product.preco"
                      :stock="product.estoque"
                      :product-img="product.fotoProduto"
                      :add-to-cart="addToCart"
                      :is-on-cart="
                      cartItems.findIndex(
                        (item) => item.sku === product.sku
                      ) !== -1
                    "
                      :total-on-cart="getProductTotal(product)"
                      :faixa-volume2="product.faixaVolume2"
                      :faixa-volume3="product.faixaVolume3"
                      :faixa-volume4="product.faixaVolume4"
                      :faixa-volume5="product.faixaVolume5"
                      :kdkg2="product.kdkg2"
                      :kdkg3="product.kdkg3"
                      :kdkg4="product.kdkg4"
                      :kdkg5="product.kdkg5"
                  />
                </div>
                <section v-show="!loadingRecents" class="m-auto mt-4">
                  <div class="row">
                    <div class="col-sm-12">
                      <nav>
                        <ul class="pagination justify-content-center mt-2">
                          <li
                              v-if="recentsPagination.currentPage != 1"
                              class="page-item prev-item"
                              @click="getRecents(recentsPagination.currentPage - 1)"
                          >
                            <a class="page-link" href="#">{{ "<" }}</a>
                          </li>
                          <li
                              v-for="page in recentsPagination.pages"
                              :key="page"
                              class="page-item"
                              @click="getRecents(page)"
                          >
                            <a
                                :class="`page-link ${page === recentsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ page }}</a
                            >
                          </li>
                          <li
                              v-if="
                              recentsPagination.lastPage >
                              recentsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getRecents(recentsPagination.lastPage - 1)"
                          >
                            <a
                                :class="`page-link ${recentsPagination.lastPage === recentsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >...</a
                            >
                          </li>
                          <li
                              v-if="
                              recentsPagination.lastPage >
                              recentsPagination.pages?.slice(-1)
                            "
                              class="page-item"
                              @click="getRecents(recentsPagination.lastPage)"
                          >
                            <a
                                :class="`page-link ${recentsPagination.lastPage === recentsPagination.currentPage ? 'bg-danger text-white' : ''}`"
                                href="#"
                            >{{ recentsPagination.lastPage }}</a
                            >
                          </li>
                          <li
                              v-if="
                              recentsPagination.currentPage !=
                              recentsPagination.lastPage
                            "
                              class="page-item last-item"
                              @click="getRecents(recentsPagination.currentPage + 1)"
                          >
                            <a class="page-link" href="#">{{ ">" }}</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': statusLastPurchase?.isOrderInProgress }">
    <div class="modal-background" @click="() => statusLastPurchase.isOrderInProgress = false"></div>
    <div class="modal-content">
      <div class="modal-header p-0 pb-2">
        <h5 id="exampleModalLabel" class="modal-title">
          Pedido com entrega programada
        </h5>
      </div>


      <div v-if="statusLastPurchase?.nextDeliveryDate" class="modal-body text-center my-4 p-0">
        <p class="mb-0">
          Notamos que você tem um pedido com entrega programada para {{ statusLastPurchase?.nextDeliveryDate }}.
        </p>
        <p class="mb-0 font-medium">
          Gostaria de fazer um pedido adicional?

        </p>
      </div>
      <div v-else class="modal-body text-center my-2 p-0 ">
        Notamos que você tem um pedido com entrega programada. Gostaria de fazer um pedido adicional?
      </div>
      <div class="modal-footer flex-row justify-content-between p-0 mt-2">
        <button
            id="close-button"
            type="button"
            class="btn btn-success"
            @click="() => router.push('/pedidos') "
        >
          Meus pedidos
        </button>


        <button
            id="close-button"
            type="button"
            class="btn btn-primary"
            @click="() => statusLastPurchase = ''"
        >
          Continuar
        </button>


      </div>

    </div>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  max-width: 100vw !important;
  overflow-x: hidden;
  padding-bottom: 15vh !important;
}

.sidebar {
  z-index: 99 !important;
  max-width: 100% !important;
  margin-bottom: 25px;

  padding: 0;
  background-color: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.sidebar.sticky {
  top: 0;
}

@media screen and (min-width: 1025px) {
  .sidebar {
    display: block;
    position: relative !important;
    width: 300px;
    padding: 20px;
    margin-right: 20px;
  }
}

.category-grid,
.category-grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  min-width: 120px;
  max-width: 120px;
}

.category-item img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.sidebar h2 {
  margin: 10px 0 10px 10px;
  font-size: 18px;

}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 5px;
  cursor: pointer;
  color: #333;
}

.sidebar ul li:hover {
  color: #666;
}

.content {
  flex: 1;
}

.orders-container {
  overflow: hidden;
  padding-top: 1rem;
}

.orders-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}


.orders-col {
  flex: 1;
}

.orders-card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0;
}

.orders-card-header {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.orders-header-content {
  max-width: 70%;
}

.orders-title {
  margin: 0;
  font-size: 1.25rem;
}

.orders-description {
  margin: 0;
  font-size: 0.875rem;
}

.orders-header-actions {
  margin-left: auto;
}

.orders-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
}

.orders-card-body {
  padding: 0;
}

.orders-section {
  background-color: #f3f4f6;
  padding: 2rem 0;
}

.orders-product-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  padding: 1rem;
  align-items: stretch;
}

.tabs-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 6px;
  padding-top: 0.5em;
  position: fixed;
  border-radius: 0.3em;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: 991;
  background-color: #fff;
}

.teste {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: .3rem;
}

#close-button {
  margin: 0 !important;
}

@media screen and (min-width: 1025px) {
  .tabs-container {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 1fr .3fr;
    position: relative;
    bottom: auto;
    right: auto;
    border-radius: 2em;
  }

  .teste {
    padding-top: 1rem;
    grid-column-start: 1;
    grid-column: span 2;
    grid-row-start: 1;
  }

  .cart {
    grid-column: span 2;
    cursor: pointer;
  }

  .tab-icon {
    max-width: 75px !important;
  }

  .orders-row {
    margin-top: 0;
  }
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.filter-button {
  display: none !important;
}

@media screen and (max-width: 1445px) {
  .orders-product-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1024px) {
  .orders-row {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    margin-right: 0px;
    width: auto;
  }

  .category-grid {
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
  }

  .filter-button {
    display: block !important;
  }
}

/* @media screen and (max-width: 575px) {
  .orders-product-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
} */

.orders-no-products {
  text-align: center;
  margin-top: 1rem;
}

.tab-icon {
  max-width: 2rem;
}

@media screen and (min-width: 550px ) {
  .tab-icon {
    max-width: 4rem;
  }

  .filter-line {
    font-size: 4em;
  }
}

.filter-menu {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 998;
  transition: transform 0.3s ease;
  transform: translateX(200%);
}

.filter-menu.show {
  transform: translateX(0);
}

.place-category {
  background-color: #f3f4f6;
  color: rgb(52, 71, 103);
  width: 100px !important;
  height: 100px;
  margin-right: 16px;
  border-radius: 50%;
}

.place-product-image {
  width: 175px !important;
  height: 175px;
  border-radius: 0.5rem;
}

.place-product {
  height: 100% !important;
  max-height: 360px !important;
  max-width: 320px !important;
  margin-right: 1rem;
}

.place-button {
  height: 43px;
  border-radius: 0.5rem;
}

.product-list {
  background-color: #f3f4f6;
  color: rgb(52, 71, 103);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5rem;
  padding: 1rem 4rem;
}

.categories-absolute {
  position: fixed;
  top: 0;
  z-index: 99999;
  max-width: 100vw !important;
  margin-left: -1.5em;
  padding: 1em;
  background-color: #fff;
  border-radius: 0.3em;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

  overflow-x: scroll;
}

.brands-absolute {
  position: fixed;
  top: 0;
  z-index: 99999;
  max-width: 100vw !important;
  margin-left: -1.5em;
  padding: 1em;
  background-color: #fff;
  border-radius: 0.3em;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

  overflow-x: scroll;
}

@media screen and (max-width: 1445px) {
  .product-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 575px) {
  .product-list {
    padding: 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .place-product {
    margin: auto;
    margin-top: 16px;
  }
}

.filter-line {
  font-size: 1em;
  position: fixed;
  padding: 0.6em;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  margin-left: -1.5em;
  background-color: #fff;
  z-index: 1000;
}

.filter-line-brand {
  font-size: 1em;
  position: fixed;
  top: 0;
  padding: 0.6em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  margin-left: -1.5em;
  background-color: #fff;
  z-index: 1000;
}


@media screen and (max-width: 1024px) {
  .sidebar {
    position: relative;
  }
}
</style>
