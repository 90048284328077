<template>

  <div class="company-card" :class="companyLogged && 'company-logged'">
    <h2 class="company-name">{{ company.name }}</h2>
    <p class="company-cnpj">{{ getDocumentType(company.cnpj_cpf) }}: {{ formatDocument(company.cnpj_cpf) }}</p>
    <p class="company-location">{{ formatLocation(company.ORT01, company.ORT02) }}</p>

    <p class="company-location">{{ company.STRAS }}</p>
    <p v-if="company.ultimoPedido"  :class="{ 'recommendation-message': isToday(company.ultimoPedido), 'company-location' : !isToday(company.ultimoPedido) }">
      Última compra dia {{ company.ultimoPedido }}
    </p>
    <p :style="{ color: recommendationColor }" class="recommendation-message">{{ recommendationMessage }}</p>
    <p v-if="isInactiveCompany" class="recommendation-message">Esta empresa ainda não está cadastrada no Conecta Brasal. Crie sua conta agora e comece a realizar pedidos!
    </p>
    <p class="days-since-last-purchase" v-if="daysSinceLastPurchase >= 7">Você realizou a sua última compra fazem
      {{ daysSinceLastPurchase }} dias.</p>


    <p v-if="companyLogged" class="logged-text">Você está nessa empresa <i class="fa fa-check-circle ms-2"
                                                                           aria-hidden="true"></i></p>
    <button v-if="!companyLogged && !isInactiveCompany" class="login-button bg-segment-primary-color" @click="loginCompany">
  <span v-if="changingCompany">
    Acessando... <i class="fa fa-spinner fa-spin ms-2" aria-hidden="true"></i>
  </span>
      <span v-else>
    Acessar conta <i class="fa fa-sign-in ms-2" aria-hidden="true"></i>
  </span>
    </button>
    <button v-else class="login-button bg-segment-primary-color" @click="router.push({name: 'Lista de Produtos'})">
      Acessar catálogo <i class="fa fa-shopping-basket ms-2" aria-hidden="true"></i>
    </button>
  </div>
</template>


<script setup>
import {computed, ref, watchEffect} from "vue";
import companiesStore from "@/store/companiesStore";
import router from "@/router";

const props = defineProps({
  company: {
    type: Object,
    required: true,
  },
  companyLogged: {
    type: Boolean,
    required: false,
    default: false,
  }
});

const emits = defineEmits(["changeCompany"]);
const changingCompany = ref(false);
const recommendationColor = ref("");

const isInactiveCompany = computed(() => {
  return props.company.situation === "INATIVO";
});
const loginCompany = () => {
  changingCompany.value = true;
  emits("changeCompany");
  const changeCompanyPromise = companiesStore.dispatch("changeCompany", props.company.clientCode);
  changeCompanyPromise.then(() => {
  }).finally(() => {
    emits("changeCompany");
    changingCompany.value = false;
  });
};

const isToday = (dateString) => {
  const today = new Date();
  const [day, month, year] = dateString.split("/");
  const date = new Date(`${year}-${month}-${day}`);
  date.setDate(date.getDate() + 1);

  return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
};
const formatLocation = (ort01, ort02) => {
  return `${ort01}, ${ort02}`;

  // if (!ort01 && !ort02) return '';
  // if (!ort02 || ort01.includes(ort02)) return ort01;
  // if (!ort01 || ort02.includes(ort01)) return ort02;
  // return `${ort01}, ${ort02}`;
};
const getDocumentType = (document) => {
  const numbers = document.replace(/\D/g, ""); // remove qualquer caractere não numérico

  if (numbers.length === 11) {
    return "CPF";
  } else if (numbers.length === 14) {
    return "CNPJ";
  } else {
    return "Documento";
  }
};
const formatDocument = (document) => {
  const numbers = document.replace(/\D/g, ""); // remove qualquer caractere não numérico

  if (numbers.length === 11) {
    // Formata como CPF: 000.000.000-00
    return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (numbers.length === 14) {
    // Formata como CNPJ: 00.000.000/0000-00
    return numbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else {
    // Retorna o documento original se não for CPF nem CNPJ
    return document;
  }
};


const convertToISO = (dateString) => {
  if (typeof dateString !== 'string') {
    return '';
  }
  const [day, month, year] = dateString.split("/");
  const date = new Date(`${year}-${month}-${day}T12:00:00`); // set time to noon
  return date;
};

const getRecommendationMessage = (lastSale, recommendedDay) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (lastSale) {
    lastSale = new Date(convertToISO(lastSale));
    lastSale.setHours(12, 0, 0, 0); // set time to noon
  }

  recommendedDay = new Date(convertToISO(recommendedDay));
  recommendedDay.setHours(12, 0, 0, 0); // set time to noon

  if (isSameDay(today, recommendedDay) && (!lastSale || !isSameDay(today, lastSale))) {
    recommendationColor.value = "#DAA520"; // Amarelo forte
    return `Hoje é o dia recomendado para compra! Aproveite as ofertas!`;
  }

  if (isSameDay(yesterday, recommendedDay) && (!lastSale || !isSameDay(yesterday, lastSale )) && !isSameDay(today, lastSale) ) {
    recommendationColor.value = "#DAA520"; // Amarelo forte
    return `Ontem foi o dia recomendado para compra. Considere realizar um pedido!`;
  }

  return '';
};
const isSameDay = (date1, date2) => {
  if (!date1 || !date2) return false;
  const result = date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  return result;
};
const calculateDaysSinceLastPurchase = (lastPurchaseDate) => {
  if (!lastPurchaseDate) return 0;
  const today = new Date();
  const [day, month, year] = lastPurchaseDate.split("/");
  const lastPurchase = new Date(`${year}-${month}-${day}`);
  const diffTime = Math.abs(today - lastPurchase);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};


const daysSinceLastPurchase = ref(0);
watchEffect(() => {
  daysSinceLastPurchase.value = calculateDaysSinceLastPurchase(props.company.ultimoPedido);
});
const recommendationMessage = ref("");
watchEffect(() => {
  recommendationMessage.value = getRecommendationMessage(props.company?.ultimoPedido, props.company.dataDeCompra);
});

</script>
<style scoped>
.company-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  align-items: start; /* alinha o conteúdo ao topo */
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  width: 100%;
}

.company-logged {
  border: 2px solid #800080;
}

.logged-text {
  color: #800080;
  font-size: 1rem;
  margin: 0;
  margin-top: .7rem;
  font-weight: bolder;
}

.company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.company-code,
.company-email {
  font-size: 0.9rem;
  margin: 0;
  margin-top: .3rem;
  color: #666;
}

.company-cnpj {
  font-size: 0.9rem;
  margin: 0;
  color: #666;
}

.company-location {
  font-size: 0.9rem;
  margin: 0;
  color: #666;
  grid-column: span 2;
  text-align: left;

}

.days-since-last-purchase {
  font-size: 1rem;
  font-weight: bold;
  color: #DD0B1C;
  margin-top: .5rem;
  margin-bottom: 0;
}

.recommendation-message {
  font-weight: bold;
  color: #4CAF50;
  margin-top: .5rem;
  margin-bottom: 0;
}

.login-button {
  width: 100%;
  margin-top: 1rem;
  padding: .5rem 1rem;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
