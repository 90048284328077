import { createStore } from "vuex";
import axios from "@/axios";
import { ref } from "vue";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
  state: {
    products: ref([]),
    promotions: ref([]),
    combos: ref([]),
    recents: ref([]),
    productsPagination: ref({}),
    promotionsPagination: ref({}),
    combosPagination: ref({}),
    recentsPagination: ref({}),
    segmentations: ref([]),
    brands: ref([]),
    loading: false,
    productsOnCart: ref(localStorage.getItem('productsOnCart') ? JSON.parse(localStorage.getItem('productsOnCart')) : []),
    selectedSegmentation: "",
    selectedBrand: "",
    clientData: ref({}),

    orderResponse: ref({}),
  },

  mutations: {
    setProducts(state, data) {
      state.products = ref(data.data.products);
      state.brands = ref(data.data.brands);

      state.productsPagination = ref(formatPagination(data.pagination));
    },
    setPromotions(state, data) {
      state.promotions = ref(data.data.products);    
      state.promotionsPagination = ref(formatPagination(data.pagination));
    },
    setCombos(state, data) {
      state.combos = ref(data.data.products);
      state.combosPagination = ref(formatPagination(data.pagination));
    },
    setRecents(state, data) {
      state.recents = ref(data.data.products);
      state.recentsPagination = ref(formatPagination(data.pagination));
    },
    setSegmentations(state, data) {
      state.segmentations = ref(data);
    },
    setProductsOnCart(state, data) {
      state.productsOnCart = ref(data);
      localStorage.setItem('productsOnCart', JSON.stringify(data))
    },
    setClientData(state, data) {
      state.clientData = ref(data);
      localStorage.setItem('clientData', JSON.stringify(data))
    },
    setOrderResponse(state, data) {
      state.orderResponse = ref(data);
    },
  },

  actions: {
    async getLastPurchaseDate(context) {
        context.state.loading = true;
            const URL = '/client/lastPurchaseStatus'
            context.state.loading = true;
            const response = await axios.get(URL);
            context.state.loading = false;
            return response.data;
    },
    async getProducts(context, params) {

      context.state.loading = true;

      const { flexibleClient, category, brand, perPage, currentPage } = params

      try {
        const URL = '/orders/catalog'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
            category: category.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            brand,
            perPage,
            currentPage,
          }
        });
        context.commit('setProducts', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getPromotions(context, params) {

      context.state.loading = true;

      const { flexibleClient, perPage, currentPage } = params

      try {
        const URL = '/orders/sales'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
            perPage,
            currentPage,
          }
        });
        context.commit('setPromotions', response.data);
        localStorage.setItem("promotions", JSON.stringify(context.state.promotions));

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getCombos(context, params) {

      context.state.loading = true;

      const { flexibleClient, perPage, currentPage } = params

      try {
        const URL = '/orders/combos'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            clientCode: flexibleClient,
            perPage,
            currentPage,
          }
        });
        context.commit('setCombos', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getRecents(context, params) {

      context.state.loading = true;

      const { flexibleClient, perPage, currentPage } = params

      try {
        const URL = '/orders/favorites'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
            days: 30,
            perPage,
            currentPage,
          }
        });
        context.commit('setRecents', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getSegmentations(context, params) {

      context.state.loading = true;

      const { flexibleClient } = params;

      try {
        const URL = '/orders/segmentations'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
          }
        });

        context.commit('setSegmentations', response.data);

        context.state.loading = false;
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async addToCart(context, params) {

      const { sku, total } = params;

      const existingProductIndex = context.state.productsOnCart.findIndex(item => item.sku === sku);

      if (existingProductIndex !== -1) {
          let product = context.state.productsOnCart[existingProductIndex];

          if (product.estoque < total) {
            toast.error('Quantidade indisponível no estoque!');
            return;
          } else if (total <= 0) {
            context.state.productsOnCart.splice(existingProductIndex, 1)
          } else {
            context.state.productsOnCart[existingProductIndex].total = total;
            context.state.productsOnCart[existingProductIndex].usePrice = checkPriceRange(context.state.productsOnCart[existingProductIndex]);
          }

      } else {
        let product = context.state.products.find((element) => element.sku === sku);
        if (!product) {
          product = context.state.promotions.find((element) => element.sku === sku);
        }
        if (!product) {
          product = context.state.combos.find((element) => element.sku === sku);
        }
        if (!product) {
          product = context.state.recents.find((element) => element.sku === sku);
        }
        if (product) {
            if (product.estoque < total) {
              toast.error('Quantidade indisponível no estoque!');
              return;
            } else if (total <= 0) {
              return;
            } else {
              product.total = total;
              product.usePrice = checkPriceRange(product)
              context.state.productsOnCart.push(product)
            }
        }
      }

      context.commit('setProductsOnCart', context.state.productsOnCart);
    },
    async clearCart(context) {
      context.state.productsOnCart = [];
    },
    async getClientData(context, params) {

      context.state.loading = true;

      const { flexibleClient } = params;

      try {
        const URL = '/orders/clientLimit'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: {
            flexibleClient,
          }
        });

        context.commit('setClientData', response.data);

        context.state.loading = false;
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
        throw error;
      }
    },
    async sendOrder(context, params) {

      context.state.loading = true;

      const { flexibleClient, products } = params;

      const sendProducts = products.map((item) => {
        return {
          faixaVolume2: item.faixaVolume2,
          faixaVolume3: item.faixaVolume3,
          faixaVolume4: item.faixaVolume4,
          faixaVolume5: item.faixaVolume5,
          kdkg2: item.kdkg2,
          kdkg3: item.kdkg3,
          kdkg4: item.kdkg4,
          kdkg5: item.kdkg5,
          price: item.price,
          sku: item.sku,
          total: item.total,
          stock: item.stock,
          usePrice: item.usePrice,
          vessel: item.vessel,
          complement: item.complemento,
          product: item.complemento,
          DATBI: item.DATBI,
          KVGR1: item.KVGR1,
          descricao: item.descricao
        }
      })

      try {
        const URL = '/orders/order'
        context.state.loading = true;
        const response = await axios.post(URL, sendProducts,
          {
          headers: {
            flexibleClient,
          }
        });

        context.commit('setOrderResponse', response.data.details);
        context.commit('setProductsOnCart', []);

        toast.success("Pedido realizado com sucesso!");
        
        context.state.loading = false;
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        }
        context.state.loading = false;
        throw error;
      }
    },
  },
});

function checkPriceRange(product) {
  let usePrice = parseFloat(product.preco.replace(',', '.'));
  if (product.total >= parseFloat(product.faixaVolume5.replace(',', '.')) && parseFloat(product.kdkg5.replace(',', '.')) > 0) {
    usePrice = parseFloat(product.kdkg5.replace(',', '.'));
  } else if (product.total >= parseFloat(product.faixaVolume4.replace(',', '.')) && parseFloat(product.kdkg4.replace(',', '.')) > 0) {
    usePrice = parseFloat(product.kdkg4.replace(',', '.'));
  } else if (product.total >= parseFloat(product.faixaVolume3.replace(',', '.')) && parseFloat(product.kdkg3.replace(',', '.')) > 0) {
    usePrice = parseFloat(product.kdkg3.replace(',', '.'));
  } else if (product.total >= parseFloat(product.faixaVolume2.replace(',', '.')) && parseFloat(product.kdkg2.replace(',', '.')) > 0) {
    usePrice = parseFloat(product.kdkg2.replace(',', '.'));
  }

  return usePrice;
}

function formatPagination(pagination) {
  let currentPage = pagination.currentPage;
  let lastPage = pagination.lastPage;
  let pages = [currentPage];

  let bottomLimit = window.innerWidth >= 490 ? 7 : 1;
  let topLimit = window.innerWidth >= 490 ? 6 : 1;

  for (let i = 1; i <= bottomLimit; i++) {
      let toAdd = currentPage - i;
      if (toAdd < 1) {
          break;
      }
      pages.push(toAdd);
  }

  for (let i = 1; i <= topLimit; i++) {
      let toAdd = currentPage + i;
      if (toAdd >= lastPage) {
          break;
      }
      pages.push(toAdd);
  }
  pages.sort((a, b) => a - b);

  pagination.pages = pages;

  return pagination
}