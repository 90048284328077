<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  indicator: {
    type: Object,
    required: true,
  },
});

const showDetailsModal = ref(false);

const chartRef = ref(null);
const chartRef2 = ref(null);

const description = computed(() => props.indicator?.description);

const percentage = computed(() =>
    props.indicator?.evaluation?.clientPercentage
        ? parseFloat(props.indicator?.evaluation?.clientPercentage).toFixed()
        : 0
);
const points = computed(() =>
    props.indicator?.evaluation?.clientPoints
        ? parseInt(props.indicator?.evaluation?.clientPoints)
        : 0
);

onMounted(() => {
  let cutout1 = 95;
  let cutout2 = 95;
  if (window.innerWidth < 1500) {
    cutout1 = 50;
    cutout2 = 50;
  }
  if (window.innerWidth < 600) {
    cutout1 = 45;
    cutout2 = 20;
  }

  new Chart(chartRef.value, {
    type: "doughnut",
    data: {
      labels: ["Feito", "Restante"],
      datasets: [
        {
          label: "",
          weight: 9,
          cutout: cutout1,
          tension: 0.9,
          pointRadius: 0,
          borderWidth: 0,
          backgroundColor: ["#4BB543", "#AAAAAA"],

          data: [percentage.value, 100 - percentage.value],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });

  new Chart(chartRef2.value, {
    type: "doughnut",
    data: {
      labels: ["Feito", "Restante"],
      datasets: [
        {
          label: "",
          weight: 9,
          cutout: cutout2,
          tension: 0.9,
          pointRadius: 0,
          borderWidth: 0,
          backgroundColor: ["#4BB543", "#AAAAAA"],

          data: [percentage.value, 100 - percentage.value],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
});
</script>

<template>
  <div>
    <div
        v-if="percentage >= 100"
        class="text-center py-2 bg-success text-white"
    >
      Indicador Concluído!
    </div>
    <div v-else class="text-center py-2 bg-gray text-white">
      Em Andamento
    </div>

    <div class="h-100 my-5 py-1 text-center">
      <div class="chart-container">
        <canvas ref="chartRef" class="chart-canvas" height="213"></canvas>
        <h4 class="font-weight-bold graph-label">
          <span>{{ percentage }}%</span>
        </h4>
      </div>
    </div>

    <div class="w-100 pt-2 text-center">
      <h5>{{ props.indicator.name }}</h5>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <button
          class="mb-0 btn btn-outline-primary export w-100 m-2 mb-2"
          @click="showDetailsModal = true"
      >
        Detalhes
      </button>
    </div>

    <div class="modal" :class="{ 'is-active': showDetailsModal }">
      <div class="modal-background" @click="showDetailsModal = false"></div>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Detalhes do Indicador
          </h5>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <h4>{{ indicator.name }}</h4>
            <small>
              Período de Apuração:<br />
              {{ `${indicator.evaluation.initialDate} até ${indicator.evaluation.finalDate}` }}
            </small>
          </div>

          <div v-if="percentage >= 100" class="label-colored bg-success mb-2">
            <div>{{ `${percentage}%` }}</div>
          </div>
          <div v-else class="label-colored bg-gray mb-2">
            <div>{{ `${percentage}%` }}</div>
          </div>

          <div class="h-100">
            <div class="row m-4 pb-5">
              <div class="text-center col-12">
                <div class="chart">
                  <canvas ref="chartRef2" class="chart-canvas" height="213"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div v-if="description" class="mb-1 mt-4">
            <label class="form-label">Descrição:</label>
            <textarea
                v-model="description"
                readonly
                class="form-control"
                rows="3"
            ></textarea>
          </div>

          <div>
            <p><b>Meta:</b> {{ `${indicator?.evaluation?.goal} ${indicator?.evaluation?.conversion_factor}` }}</p>
            <p><b>Notas aprovadas:</b> {{ `${indicator?.evaluation?.total} ${indicator?.evaluation?.conversion_factor}` }}</p>
            <p><b>Pontos:</b> {{ `${points}/${indicator?.evaluation?.totalPoints}` }}</p>
            <p><b>Promoção:</b> {{indicator?.invoice_campaign?.name}}</p>
          </div>
        </div>

        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-primary"
              @click="showDetailsModal = false"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-gray {
  background-color: #cccccc;
}

.modal {
  display: none;
}

.modal.is-active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}

@media screen and (max-width: 1150px) {
  .modal-title {
    width: 100%;
    text-align: center;
  }

  .modal-content {
    max-width: 100% !important;
    width: 80% !important;
  }
}

.label-colored {
  width: 80%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}

.chart-container {
  position: relative;
}

.graph-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-canvas {
  max-width: 100% !important;
}
</style>
