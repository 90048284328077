<script setup>
import {computed, onMounted, ref} from "vue";
import useOrdersStore from "../../../../store/OrdersStore";

import colors from "./colors";
import Loading from "@/components/Loading.vue";
import axios from "@/axios";
import {saveAs} from "file-saver";

const store = useOrdersStore();

const orders = ref([]);

const loadingData = ref(true);
const loadingNF = ref(false);

const SAPOrders = computed(() =>
    orders?.value?.statusPedidos ? orders?.value?.statusPedidos?.length ? orders?.value?.statusPedidos : [orders?.value?.statusPedidos] : []
);

const error = ref(false)

const nonProcessedOrders = computed(() =>
    orders?.value?.pedidosNaoProcessados
        ? orders?.value?.pedidosNaoProcessados
        : []
);

onMounted(async () => {
  try {
    const myOrdersPromise = store.dispatch("getData");

    await Promise.all([
      myOrdersPromise.then(() => {
        orders.value = store?.state?.myOrders;
        loadingData.value = false;
        error.value = store?.state?.error;
      }).catch(() => {
        loadingData.value = false;
        error.value = store?.state?.error;
      })
    ]);
  } catch (error) {
    error.value = true;
    loadingData.value = false;
  }
});

function downloadNF(nf) {
  loadingNF.value = true;
  const nfkey = Array.isArray(nf)
    ? nf.filter(nota => typeof nota?.CHAVE_NF === 'string').map(nota => nota?.CHAVE_NF)
    : typeof nf?.CHAVE_NF === 'string' ? [nf?.CHAVE_NF] : [];

  axios({
    url: 'client/myOrderNF',
    method: 'POST',
    responseType: 'blob', // Importante para garantir que o axios processe a resposta como um Blob
    data: {nfkey},
  })
      .then((response) => {
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        saveAs(blob, 'nf.pdf');
      })
      .catch((error) => {
        console.error("Erro ao baixar NF", error);
      })
      .finally(() => loadingNF.value = false);

}

function formatDate(dataString) {
  if (dataString) {
    var partes = dataString.split("-");
    var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
    return novaData;
  } else {
    return 'Data Inválida'
  }
}

const undesiredValues = [
  4011, 4036, 4037, 8266, 8296, 8297, 8298, 8299, 8300, 8301, 8302, 12379,
  12380, 4051, 4056, 4426, 4427, 4428, 4429,
];
</script>

<template>
  <div class="container">
    <div class="box">
      <section id="faq-search-filter" style="margin-top: 15px">
        <div class="card faq-search">
          <div class="card-body text-center">
            <!-- main title -->
            <h2 class="">Pedidos</h2>
            <p class="card-text mb-2">
              Histórico de pedidos de produtos Brasal Refrigerantes
            </p>
          </div>
        </div>
      </section>

      <Loading v-if="loadingData"/>
      <div
          v-else-if="
          !SAPOrders?.length && !nonProcessedOrders?.length && !loadingData && !error
        "
          class="text-center alert-warning p-4 mt-5 rounded"
          role="alert"
      >
        Nenhum pedido encontrado nos últimos 30 dias.
        <router-link :to="{ name: 'Lista de Produtos' }"
        >Clique aqui para realizar novo pedido.
        </router-link>
      </div>

     <div
    v-else-if="error && error?.details"
    class="text-center alert-custom p-4 mt-5 rounded"
    role="alert"
>
  {{ error.details }}
</div>
      <div
          v-else-if="error && !error?.details"
          class="text-center alert-custom p-4 mt-5 rounded"
          role="alert"
      >
        Ocorreu um erro ao carregar os pedidos. Por favor, tente novamente
        mais tarde.
      </div>

      <!--Inicio Pedido -->
      <div
          v-for="(request, index) in SAPOrders"
          :key="index"
          class="card mt-5 mb-4"
      >
        <div
            class="card-header d-flex justify-content-between align-items-center"
        >
          <h4><b>PEDIDO:</b> {{ request?.Status?.VBELN }}</h4>
          <div>
            <b>TIPO DO PEDIDO: </b>
            <span v-show="request?.Status?.AUART == 'ZS03'">BONIFICAÇÃO</span>
            <span v-show="request?.Status?.AUART == 'ZS11'">TROCA</span>
            <span v-show="request?.Status?.AUART == 'ZS43'">COMPRA</span>
          </div>
          <div>
            <b>DATA: </b>
            {{ formatDate(request?.Status?.ERDAT) }}
          </div>
        </div>
        <div class="card-body">
          <ul class="list-unstyled">
            <li class="d-flex flex-row justify-content-between">
              <div>
                <span
                    class="me-2"
                    :class="`text-${colors(request?.Status?.STATUS).cor_ped}`"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-cart"
                  >
                    <circle cx="9" cy="21" r="1"></circle>
                    <circle cx="20" cy="21" r="1"></circle>
                    <path
                        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                    ></path>
                  </svg>
                </span>
                <span
                    v-if="colors(request?.Status?.STATUS).cor_ped == 'success'"
                >
                  Pedido Confirmado
                </span>
                <span
                    v-else-if="
                    colors(request?.Status?.STATUS).cor_ped == 'danger'
                  "
                >
                  Pedido Cancelado
                </span>
                <span v-else> Não Realizado </span>
              </div>
              <div>
                <span
                    style="cursor: pointer"
                    class="bg-gray rounded-pill px-3 py-1"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse1${request?.Status?.VBELN}`"
                    aria-expanded="true"
                    aria-controls="collapse"
                >Detalhes</span
                >
              </div>
            </li>
            <div
                v-if="Array.isArray(request?.Itens?.Item)"
                :id="`collapse1${request?.Status?.VBELN}`"
                class="collapse my-4 mx-auto"
            >
              <div
                  v-for="(item, index) in request?.Itens?.Item"
                  :key="index"
                  class="mt-2 mb-4"
              >
                <div class="d-lg-none">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>Código:</b> {{ parseInt(item?.MATNR) }}
                    </li>
                    <li class="list-group-item">
                      <b>Produto:</b> {{ item?.ARKTX }}
                    </li>
                    <li class="list-group-item">
                      <b>Quantidade:</b> {{ parseInt(item?.KWMENG) }}
                    </li>
                    <li class="list-group-item">
                      <b>Situação:</b>
                      <div v-if="item?.BEZEI?.length">
                        <template v-if="item?.BEZEI === 'NF Denegada'">
                          <span class="badge rounded-pill badge-light-danger">
                            Nota fiscal denegada
                          </span>
                        </template>
                        <template v-else-if="item?.BEZEI === 'Canc. Cliente'">
                          <span class="badge rounded-pill badge-light-danger">
                            Cancelamento solicitado pelo cliente
                          </span>
                        </template>
                        <template v-else-if="item?.BEZEI === 'Ruptura'">
                          <span class="badge rounded-pill badge-light-danger">
                            Ruptura de produto
                          </span>
                        </template>
                        <template
                            v-else-if="item?.BEZEI === 'Título em Atraso'"
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Título em Atraso
                          </span>
                        </template>
                        <template
                            v-else-if="item?.BEZEI === 'Limite de Crédito'"
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Limite de Crédito
                          </span>
                        </template>
                        <template v-else-if="item?.BEZEI === 'Insc. Suspensa'">
                          <span class="badge rounded-pill badge-light-danger">
                            Inscrição Suspensa
                          </span>
                        </template>
                        <template v-else-if="item?.BEZEI === 'Fora de Rota'">
                          <span class="badge rounded-pill badge-light-danger">
                            Fora de Rota
                          </span>
                        </template>
                        <template v-else>
                          <span class="badge rounded-pill badge-danger"
                          >Cancelado</span
                          >
                        </template>
                      </div>
                      <div v-else>
                        <template
                            v-if="
                            request?.Status.STATUS == 'PARCIALMENTE ENTREGUE' ||
                            request?.Status.STATUS == 'PEDIDO ENTREGUE' ||
                            request?.Status.STATUS ==
                              'PEDIDO PARCIALMENTE RECUSADO' ||
                            request?.Status.STATUS == 'PEDIDO EM TRANSITO'
                          "
                        >
                          <span class="text-success">Confirmado</span>
                        </template>
                        <template
                            v-else-if="
                            ped?.Status.STATUS === 'CANCELADO' ||
                            ped?.Status.STATUS === 'PEDIDO CANCELADO' ||
                            ped?.Status.STATUS === 'PEDIDO NAO FATURADO' ||
                            ped?.Status.STATUS === 'PEDIDO NOTA CANCELADA' ||
                            ped?.Status.STATUS === 'PEDIDO RETORNADO' ||
                            ped?.Status.STATUS === 'RETORNADO/DEVOLVIDO'
                          "
                        >
                          <span class="text-danger">Cancelado</span>
                        </template>
                        <template v-else>
                          <span>Sem Recusa</span>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="table-responsive d-none d-lg-block">
                  <table
                      v-if="
                      (request?.Status?.AUART === 'ZS03' ||
                        request?.Status?.AUART === 'ZS43') &&
                      !undesiredValues.includes(item?.MATNR)
                    "
                      class="table table-striped"
                  >
                    <tr>
                      <th scope="row">Código:</th>
                      <td style="word-break: break-all">
                        {{ parseInt(item?.MATNR) }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Produto:</th>
                      <td style="word-break: break-all">
                        {{ item?.ARKTX }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Quantidade:</th>
                      <td style="word-break: break-all">
                        {{ parseInt(item?.KWMENG) }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Situação:</th>
                      <td style="word-break: break-all">
                        <div v-if="item?.BEZEI?.length" class="row">
                          <template v-if="item?.BEZEI === 'NF Denegada'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Nota fiscal denegada</span
                            >
                          </template>
                          <template v-else-if="item?.BEZEI === 'Canc. Cliente'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Cancelamento solicitado pelo cliente</span
                            >
                          </template>
                          <template v-else-if="item?.BEZEI === 'Ruptura'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Ruptura de produto</span
                            >
                          </template>
                          <template
                              v-else-if="item?.BEZEI === 'Título em Atraso'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Título em Atraso</span
                            >
                          </template>
                          <template
                              v-else-if="item?.BEZEI === 'Limite de Crédito'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Limite de Crédito</span
                            >
                          </template>
                          <template
                              v-else-if="item?.BEZEI === 'Insc. Suspensa'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Inscrição Suspensa</span
                            >
                          </template>
                          <template v-else-if="item?.BEZEI === 'Fora de Rota'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Fora de Rota</span
                            >
                          </template>
                          <template v-else>
                            <span class="badge rounded-pill badge-danger"
                            >Cancelado</span
                            >
                          </template>
                          <br/>
                        </div>
                        <div v-else class="row">
                          <template
                              v-if="
                              request?.Status.STATUS ==
                                'PARCIALMENTE ENTREGUE' ||
                              request?.Status.STATUS == 'PEDIDO ENTREGUE' ||
                              request?.Status.STATUS ==
                                'PEDIDO PARCIALMENTE RECUSADO' ||
                              request?.Status.STATUS == 'PEDIDO EM TRANSITO'
                            "
                          >
                            <span class="text-success">Confirmado</span>
                          </template>
                          <template
                              v-else-if="
                              ped?.Status.STATUS === 'CANCELADO' ||
                              ped?.Status.STATUS === 'PEDIDO CANCELADO' ||
                              ped?.Status.STATUS === 'PEDIDO NAO FATURADO' ||
                              ped?.Status.STATUS === 'PEDIDO NOTA CANCELADA' ||
                              ped?.Status.STATUS === 'PEDIDO RETORNADO' ||
                              ped?.Status.STATUS === 'RETORNADO/DEVOLVIDO'
                            "
                          >
                            <span class="text-danger">Cancelado</span>
                          </template>
                          <template v-else>
                            <span>Sem Recusa</span>
                          </template>
                          <br/>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div
                v-else
                :id="`collapse1${request?.Status?.VBELN}`"
                class="collapse my-4 mx-auto"
            >
              <div :key="request?.Itens?.Item?.MATNR" class="mt-2 mb-4">
                <div class="d-lg-none">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>Código:</b> {{ parseInt(request?.Itens?.Item?.MATNR) }}
                    </li>
                    <li class="list-group-item">
                      <b>Produto:</b> {{ request?.Itens?.Item?.ARKTX }}
                    </li>
                    <li class="list-group-item">
                      <b>Quantidade:</b>
                      {{ parseInt(request?.Itens?.Item?.KWMENG) }}
                    </li>
                    <li class="list-group-item">
                      <b>Situação:</b>
                      <div v-if="request?.Itens?.Item?.BEZEI?.length">
                        <template
                            v-if="request?.Itens?.Item?.BEZEI === 'NF Denegada'"
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Nota fiscal denegada
                          </span>
                        </template>
                        <template
                            v-else-if="
                            request?.Itens?.Item?.BEZEI === 'Canc. Cliente'
                          "
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Cancelamento solicitado pelo cliente
                          </span>
                        </template>
                        <template
                            v-else-if="request?.Itens?.Item?.BEZEI === 'Ruptura'"
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Ruptura de produto
                          </span>
                        </template>
                        <template
                            v-else-if="
                            request?.Itens?.Item?.BEZEI === 'Título em Atraso'
                          "
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Título em Atraso
                          </span>
                        </template>
                        <template
                            v-else-if="
                            request?.Itens?.Item?.BEZEI === 'Limite de Crédito'
                          "
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Limite de Crédito
                          </span>
                        </template>
                        <template
                            v-else-if="
                            request?.Itens?.Item?.BEZEI === 'Insc. Suspensa'
                          "
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Inscrição Suspensa
                          </span>
                        </template>
                        <template
                            v-else-if="
                            request?.Itens?.Item?.BEZEI === 'Fora de Rota'
                          "
                        >
                          <span class="badge rounded-pill badge-light-danger">
                            Fora de Rota
                          </span>
                        </template>
                        <template v-else>
                          <span class="badge rounded-pill badge-danger"
                          >Cancelado</span
                          >
                        </template>
                      </div>
                      <div v-else>
                        <template
                            v-if="
                            request?.Status.STATUS == 'PARCIALMENTE ENTREGUE' ||
                            request?.Status.STATUS == 'PEDIDO ENTREGUE' ||
                            request?.Status.STATUS ==
                              'PEDIDO PARCIALMENTE RECUSADO' ||
                            request?.Status.STATUS == 'PEDIDO EM TRANSITO'
                          "
                        >
                          <span class="text-success">Confirmado</span>
                        </template>
                        <template
                            v-else-if="
                            ped?.Status.STATUS === 'CANCELADO' ||
                            ped?.Status.STATUS === 'PEDIDO CANCELADO' ||
                            ped?.Status.STATUS === 'PEDIDO NAO FATURADO' ||
                            ped?.Status.STATUS === 'PEDIDO NOTA CANCELADA' ||
                            ped?.Status.STATUS === 'PEDIDO RETORNADO' ||
                            ped?.Status.STATUS === 'RETORNADO/DEVOLVIDO'
                          "
                        >
                          <span class="text-danger">Cancelado</span>
                        </template>
                        <template v-else>
                          <span>Sem Recusa</span>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="table-responsive d-none d-lg-block">
                  <table
                      v-if="
                      (request?.Status?.AUART === 'ZS03' ||
                        request?.Status?.AUART === 'ZS43') &&
                      !undesiredValues.includes(request?.Itens?.Item?.MATNR)
                    "
                      class="table table-striped"
                  >
                    <tr>
                      <th scope="row">Código:</th>
                      <td style="word-break: break-all">
                        {{ parseInt(request?.Itens?.Item?.MATNR) }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Produto:</th>
                      <td style="word-break: break-all">
                        {{ request?.Itens?.Item?.ARKTX }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Quantidade:</th>
                      <td style="word-break: break-all">
                        {{ parseInt(request?.Itens?.Item?.KWMENG) }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Situação:</th>
                      <td style="word-break: break-all">
                        <div v-if="request?.Itens?.Item?.BEZEI?.length" class="row">
                          <template v-if="request?.Itens?.Item?.BEZEI === 'NF Denegada'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Nota fiscal denegada</span
                            >
                          </template>
                          <template v-else-if="request?.Itens?.Item?.BEZEI === 'Canc. Cliente'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Cancelamento solicitado pelo cliente</span
                            >
                          </template>
                          <template v-else-if="request?.Itens?.Item?.BEZEI === 'Ruptura'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Ruptura de produto</span
                            >
                          </template>
                          <template
                              v-else-if="request?.Itens?.Item?.BEZEI === 'Título em Atraso'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Título em Atraso</span
                            >
                          </template>
                          <template
                              v-else-if="request?.Itens?.Item?.BEZEI === 'Limite de Crédito'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Limite de Crédito</span
                            >
                          </template>
                          <template
                              v-else-if="request?.Itens?.Item?.BEZEI === 'Insc. Suspensa'"
                          >
                            <span class="badge rounded-pill badge-light-danger"
                            >Inscrição Suspensa</span
                            >
                          </template>
                          <template v-else-if="request?.Itens?.Item?.BEZEI === 'Fora de Rota'">
                            <span class="badge rounded-pill badge-light-danger"
                            >Fora de Rota</span
                            >
                          </template>
                          <template v-else>
                            <span class="badge rounded-pill badge-danger"
                            >Cancelado</span
                            >
                          </template>
                          <br/>
                        </div>
                        <div v-else class="row">
                          <template
                              v-if="
                              request?.Status.STATUS ==
                                'PARCIALMENTE ENTREGUE' ||
                              request?.Status.STATUS == 'PEDIDO ENTREGUE' ||
                              request?.Status.STATUS ==
                                'PEDIDO PARCIALMENTE RECUSADO' ||
                              request?.Status.STATUS == 'PEDIDO EM TRANSITO'
                            "
                          >
                            <span class="text-success">Confirmado</span>
                          </template>
                          <template
                              v-else-if="
                              ped?.Status.STATUS === 'CANCELADO' ||
                              ped?.Status.STATUS === 'PEDIDO CANCELADO' ||
                              ped?.Status.STATUS === 'PEDIDO NAO FATURADO' ||
                              ped?.Status.STATUS === 'PEDIDO NOTA CANCELADA' ||
                              ped?.Status.STATUS === 'PEDIDO RETORNADO' ||
                              ped?.Status.STATUS === 'RETORNADO/DEVOLVIDO'
                            "
                          >
                            <span class="text-danger">Cancelado</span>
                          </template>
                          <template v-else>
                            <span>Sem Recusa</span>
                          </template>
                          <br/>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <li class="mt-4">
              <span
                  class="me-2"
                  :class="`text-${colors(request?.Status?.STATUS).cor_analise}`"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-dollar-sign"
                >
                  <line x1="12" y1="1" x2="12" y2="23"></line>
                  <path
                      d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                  ></path>
                </svg>
              </span>
              <span
                  v-if="colors(request?.Status?.STATUS).cor_analise == 'success'"
              >
                Análise de Crédito
              </span>
              <span
                  v-else-if="
                  colors(request?.Status?.STATUS).cor_analise == 'danger'
                "
              >
                Análise de Crédito Reprovada
              </span>
              <span v-else> Não realizada </span>
            </li>
            <li class="d-flex flex-row justify-content-between mt-4">
              <div>
                <span
                    class="me-2"
                    :class="`text-${colors(request?.Status?.STATUS).cor_faturado}`"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-file-text"
                  >
                    <path
                        d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                    ></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                  </svg>
                </span>
                <span> Pedido Faturado </span>
              </div>
              <div>
                <span
                    v-if="request?.Notas?.Nota"
                    style="cursor: pointer"
                    class="bg-gray rounded-pill px-3 py-1"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse2${request?.Status?.VBELN}`"
                    aria-expanded="true"
                    aria-controls="collapse"
                >Detalhes</span
                >
              </div>
            </li>
            <div
                v-if="request?.Notas?.Nota && Array.isArray(request?.Notas?.Nota)"
                :id="`collapse2${request?.Status?.VBELN}`"
                class="collapse my-4 mx-auto"
            >
              <div
v-if="request?.Notas?.Nota && request?.Notas?.Nota?.filter(
    (nota) => nota?.CHAVE_NF && nota?.CHAVE_NF !== ''
).length > 0">
                <button
class="rounded-pill px-3 py-1 border-0 text-white flex flex-row justify-content-around align-items-center"
                        @click="downloadNF(request?.Notas?.Nota?.filter(
                                (nota) => nota?.NFENUM !== '' || true
                              ))"
:class="loadingNF ? 'bg-secondary' : 'bg-primary'"
                :disabled="loadingNF"
                >
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  Baixar NF
                </button>
              </div>
              <div
                  v-for="(nota, index) in request?.Notas?.Nota?.filter(
                                (nota) => nota?.NFENUM !== '' || true
                              )"
                  :key="index"
                  class="mt-2 mb-4"
              >


                <div
                    v-if="nota?.NFENUM != '-'"
                    class="d-flex flex-column justify-content-between d-lg-none"
                >
                  <div>
                    <span class="fw-bold me-2">Nota Fiscal:</span>
                    <span v-if="nota?.NFENUM != '-'">{{ nota?.NFENUM }}</span>
                    <span v-else>-</span>
                  </div>
                  <div>
                    <span class="fw-bold me-2">Data:</span>
                    <span v-if="nota?.DOCDAT">{{
                        formatDate(nota?.DOCDAT)
                      }}</span>
                    <span v-else>-</span>
                  </div>
                  <div>
                    <span class="fw-bold me-2">Status:</span>
                    <span v-if="nota?.CANCEL == 'X'">Cancelada</span>
                    <span v-else-if="nota?.DOCSTAT == '1'">Validada</span>
                    <span v-else-if="nota?.DOCSTAT == '2'">Recusada</span>
                    <span v-else-if="nota?.DOCSTAT == '3'">Rejeitada</span>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="table-responsive d-none d-lg-block">
                  <table
                      v-if="nota?.NFENUM != '-'"
                      class="tbl_pedidos table table-striped table-condensed table-bordered"
                  >
                    <tr>
                      <th scope="row">Nota Fiscal</th>
                      <td align:center>{{ nota?.NFENUM }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Data</th>
                      <td align:center>
                        {{ nota?.DOCDAT ? formatDate(nota?.DOCDAT) : "" }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td v-if="nota?.CANCEL == 'X'" align:center>Cancelada</td>
                      <td v-else-if="nota?.DOCSTAT == '1'" align:center>
                        Validada
                      </td>
                      <td v-else-if="nota?.DOCSTAT == '2'" align:center>
                        Recusada
                      </td>
                      <td v-else-if="nota?.DOCSTAT == '3'" align:center>
                        Rejeitada
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
                v-else-if="
                request?.Notas?.Nota && !Array.isArray(request?.Notas?.Nota)
              "
                :id="`collapse2${request?.Status?.VBELN}`"
                class="collapse my-4 mx-auto"
            >
              <div v-if="request?.Notas?.Nota?.CHAVE_NF">
                <button
                    class="rounded-pill px-3 py-1 border-0 text-white flex flex-row justify-content-around align-items-center"
                    :class="loadingNF ? 'bg-secondary' : 'bg-primary'"
                    :disabled="loadingNF"

                        @click="downloadNF(request?.Notas?.Nota)">
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  Baixar NF
                </button>
              </div>
              <div :key="request?.Notas?.Nota?.NFENUM" class="mt-2 mb-4">
                <div
                    v-if="request?.Notas?.Nota?.NFENUM != '-'"
                    class="d-flex flex-column justify-content-between d-lg-none"
                >
                  <div>
                    <span class="fw-bold me-2">Nota Fiscal:</span>
                    <span v-if="request?.Notas?.Nota?.NFENUM != '-'">{{
                        request?.Notas?.Nota?.NFENUM
                      }}</span>
                    <span v-else>-</span>
                  </div>
                  <div>
                    <span class="fw-bold me-2">Data:</span>
                    <span v-if="request?.Notas?.Nota?.DOCDAT">{{
                        formatDate(request?.Notas?.Nota?.DOCDAT)
                      }}</span>
                    <span v-else>-</span>
                  </div>
                  <div>
                    <span class="fw-bold me-2">Status:</span>
                    <span v-if="request?.Notas?.Nota?.CANCEL == 'X'"
                    >Cancelada</span
                    >
                    <span v-else-if="request?.Notas?.Nota?.DOCSTAT == '1'"
                    >Validada</span
                    >
                    <span v-else-if="request?.Notas?.Nota?.DOCSTAT == '2'"
                    >Recusada</span
                    >
                    <span v-else-if="request?.Notas?.Nota?.DOCSTAT == '3'"
                    >Rejeitada</span
                    >
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="table-responsive d-none d-lg-block">
                  <table
                      v-if="request?.Notas?.Nota?.NFENUM != '-'"
                      class="tbl_pedidos table table-striped table-condensed table-bordered"
                  >
                    <tr>
                      <th scope="row">Nota Fiscal</th>
                      <td align:center>{{ request?.Notas?.Nota?.NFENUM }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Data</th>
                      <td align:center>
                        {{
                          request?.Notas?.Nota?.DOCDAT
                              ? formatDate(request?.Notas?.Nota?.DOCDAT)
                              : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td
                          v-if="request?.Notas?.Nota?.CANCEL == 'X'"
                          align:center
                      >
                        Cancelada
                      </td>
                      <td
                          v-else-if="request?.Notas?.Nota?.DOCSTAT == '1'"
                          align:center
                      >
                        Validada
                      </td>
                      <td
                          v-else-if="request?.Notas?.Nota?.DOCSTAT == '2'"
                          align:center
                      >
                        Recusada
                      </td>
                      <td
                          v-else-if="request?.Notas?.Nota?.DOCSTAT == '3'"
                          align:center
                      >
                        Rejeitada
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <li class="mt-4">
              <span
                  class="me-2"
                  :class="`text-${colors(request?.Status?.STATUS).cor_separacao}`"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-settings"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path
                      d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                  ></path>
                </svg>
              </span>
              <span>Separação Interna</span>
            </li>

            <li class="d-flex flex-row justify-content-between mt-4">
              <div>
                <span
                    class="me-2"
                    :class="`text-${colors(request?.Status?.STATUS).cor_encaminhado}`"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-truck"
                  >
                    <rect x="1" y="3" width="15" height="13"></rect>
                    <polygon
                        points="16 8 20 8 23 11 23 16 16 16 16 8"
                    ></polygon>
                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                  </svg>
                </span>
                <span>Pedido em Trânsito</span>
              </div>
              <div
                  v-if="
                  ['success', 'warning'].includes(
                    colors(request?.Status?.STATUS).cor_encaminhado
                  )
                "
              >
                <span
                    style="cursor: pointer"
                    class="bg-gray rounded-pill px-3 py-1"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse3${request?.Status?.VBELN}`"
                    aria-expanded="true"
                    aria-controls="collapse"
                >Detalhes</span
                >
              </div>
            </li>
            <div
                :id="`collapse3${request?.Status?.VBELN}`"
                class="collapse my-4 mx-auto"
            >
              <div
                  :id="`collapse3${request?.Status?.VBELN}`"
                  class="collapse my-4 mx-auto d-lg-none"
              >
                <div class="mt-2 mb-4">
                  <div v-if="request?.Transporte?.FROTA">
                    <b>Frota:</b> {{ request?.Transporte?.FROTA }}
                  </div>
                  <div v-if="request?.Transporte?.PLACA">
                    <b>Placa:</b> {{ request?.Transporte?.PLACA }}
                  </div>
                  <div v-if="request?.Transporte?.MOTORISTA_NOME">
                    <b>Motorista:</b> {{ request?.Transporte?.MOTORISTA_NOME }}
                  </div>
                  <div v-if="request?.Transporte?.MOTORISTA_TEL?.length">
                    <b>Tel. Motorista:</b>
                    {{ request?.Transporte?.MOTORISTA_TEL }}
                  </div>
                  <div v-else><b>Tel. Motorista:</b> Indisponível</div>
                </div>
              </div>
              <div class="table-responsive d-none d-lg-block">
                <table
                    class="tbl_pedidos table table-striped table-condensed table-bordered"
                >
                  <tr>
                    <th scope="row">Frota:</th>
                    <td style="word-break: break-all">
                      {{ request?.Transporte?.FROTA }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Placa</th>
                    <td style="word-break: break-all">
                      {{ request?.Transporte?.PLACA }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Motorista</th>
                    <td style="word-break: break-all">
                      {{ request?.Transporte?.MOTORISTA_NOME }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Tel. Motorista</th>
                    <td style="word-break: break-all">
                      {{
                        request?.Transporte?.MOTORISTA_TEL?.length
                            ? request?.Transporte?.MOTORISTA_TEL
                            : "Indisponível"
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <li class="mt-4">
              <span
                  class="me-2"
                  :class="`text-${colors(request?.Status?.STATUS).cor_entregue}`"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-target"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <circle cx="12" cy="12" r="6"></circle>
                  <circle cx="12" cy="12" r="2"></circle>
                </svg>
              </span>
              <span
                  v-if="colors(request?.Status?.STATUS).cor_entregue == 'success'"
              >
                Pedido Entregue
              </span>
              <span
                  v-else-if="
                  colors(request?.Status?.STATUS).cor_entregue == 'warning'
                "
              >
                Pedido Entregue Parcialmente
              </span>
              <span v-else>Aguardando</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- Fim do Pedido -->
    </div>
  </div>
</template>
<style>
.container {
  flex: 1;
}

#faq-search-filter {
  margin-right: 4px;
  margin-left: -3px;
}

.box {
  width: 100%;
}

.bg-gray {
  background-color: #cccccc;
}
 .alert-custom {
   background: linear-gradient(to right, #ffdddd, #ff7f7f);
   color: #721c24;
 }
.cab_tab {
  width: 50%;
}

.tbl_pedidos {
  width: 95%;
}

.mobile {
  display: none;
}

.web {
  display: block;
}

@media screen and (max-width: 550px) {
  .card-header {
    flex-direction: column;
    text-align: center;
  }

  .mobile {
    display: block;
  }

  .web {
    display: none;
  }
}
</style>
