import { createStore } from "vuex";
import axios from "@/axios";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
  state: {
    loading: false,
  },

  mutations: {
  },

  actions: {
    async sendImage(context, params) {

      context.state.loading = true;

      const { 
        flexibleCampaignId,
        flexiblePeriodId,
        flexibleParamIndicatorId,
        flexibleClientCode,
        storage,
      } = params;


      try {
        const URL = '/campaigns/flexibles/photographies/';
        context.state.loading = true;
    
        const formData = new FormData();
        formData.append('flexibleCampaignId', flexibleCampaignId);
        formData.append('flexiblePeriodId', flexiblePeriodId);
        formData.append('flexibleParamIndicatorId', flexibleParamIndicatorId);
        formData.append('flexibleClientCode', flexibleClientCode);
        formData.append('storage', base64toFile(storage, 'image.png'));
    
        const response = await axios.post(URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    
        toast.success(response?.data?.details);
        context.state.loading = false;
      } catch (error) {
          console.log(error);
          if (error?.response?.data?.details) {
              toast.error(error?.response?.data?.details);
          } else {
              toast.error(error?.message);
          }
          context.state.loading = false;
      }
    },
  },
});

function base64toFile(base64String) {
  const parts = base64String.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([uInt8Array], { type: contentType });

  const file = new File([blob], "image.png", { type: contentType });
  return file;
}