// formStore.js
// import axios from "@/axios";

import axios from "@/axios";
import showToast from "@/utils/showToast";
import {createStore} from "vuex";
import router from "@/router";

export default () => createStore({
    state: {
        clientFound: '',
        clientCode: '',
        cpfCnpj: '',
        ageCheck: false,
        answers: [],
        username: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        policyCheck: false,
        notificationCheck: false,
        restricted: false,
        restrictionTitle: '',
        restrictionMessage: '',
        finished: false,
        loadingFinish: false,
    },
    mutations: {
        setClientCode(state, payload) {
            state.clientCode = payload;
        },
        setCpfCnpj(state, payload) {
            state.cpfCnpj = payload;
        },
        setAgeCheck(state, payload) {
            state.ageCheck = payload;
        },
        setAnswers(state, payload) {
            state.answers = payload;
        },
        setUsername(state, payload) {
            state.username = payload;
        },
        setEmail(state, payload) {
            state.email = payload;
        },
        setConfirmEmail(state, payload) {
            state.confirmEmail = payload;
        },
        setPassword(state, payload) {
            state.password = payload;
        },
        setConfirmPassword(state, payload) {
            state.confirmPassword = payload;
        },
        setPolicyCheck(state, payload) {
            state.policyCheck = payload;
        },
        setNotificationCheck(state, payload) {
            state.notificationCheck = payload;
        },
        setRestricted(state, payload) {
            state.restricted = payload;
        },
        setRestrictionTitle(state, payload) {
            state.restrictionTitle = payload;
        },
        setRestrictionMessage(state, payload) {
            state.restrictionMessage = payload;
        },
        setFinished(state, payload) {
            state.finished = payload;
        },
        clearForm(state) {
            state.clientCode = '';
            state.cpfCnpj = '';
            state.ageCheck = false;
            state.username = '';
            state.email = '';
            state.confirmEmail = '';
            state.password = '';
            state.confirmPassword = '';
            state.policyCheck = false;
            state.notificationCheck = false;
            state.restricted = false;
            state.restrictionTitle = '';
            state.restrictionMessage = '';
            state.finished = false;
        },
        setClientFound(state, payload) {
            state.clientFound = payload;
        },
        setLoadingFinish(state, payload) {
            state.loadingFinish = payload;
        }

    },
    actions: {
        async initialRegister({commit}, payload) {
            commit('setClientCode', payload.clientCode);
            commit('setCpfCnpj', payload.cpfCnpj);
            commit('setAgeCheck', payload.ageCheck);

            await axios.post('/auth/register', {
                clientCode: payload.clientCode,
                username: payload.cpfCnpj,
                ageCheck: payload.ageCheck,
            })
                .then(() => { 
                    commit('setClientFound', true);
                })
                .catch(error => {
                    commit('setClientFound', false);
                    if (error.response && error.response.status !== 404) {
                        commit('setRestricted', true);
                        commit('setRestrictionTitle', error.response.data.message);
                        commit('setRestrictionMessage', error.response.data.details);
                    } else if (error.response) {
                        showToast('error', error.response.data.details);
                    }
                });


        },
        async validateToken({commit}, payload) {
            axios.post('/auth/validate-token', {
                token: payload.token,
            })
                .then(() => {
                    commit('setFinished', true);
                    showToast('success', 'Token validado com sucesso! Realize o login para acessar o sistema.');
                    const isWaitingValidEmail = localStorage.getItem('isWaitingValidEmail');
                    if (isWaitingValidEmail) {
                        localStorage.removeItem('isWaitingValidEmail');
                    }
                    setTimeout(() => {
                        router.push({name: 'SignIn'});
                    }, 3500);
                })
                .catch(error => {
                    if (error.response) {
                        showToast('error', error.response.data.details);
                    }
                });
        },
        clearForm({commit}) {
            commit('setClientCode', '');
            commit('setCpfCnpj', '');
            commit('setAgeCheck', false);
            commit('setQuestions', []);
            commit('setUsername', '');
            commit('setEmail', '');
            commit('setConfirmEmail', '');
            commit('setPassword', '');
            commit('setConfirmPassword', '');
            commit('setPolicyCheck', false);
            commit('setNotificationCheck', false);
            commit('setQuestions', []);
        },
        clearRestriction({commit}) {
            commit('setRestricted', false);
            commit('setRestrictionTitle', '');
            commit('setRestrictionMessage', '');
        },
        async finalizeRegister({commit, state}, payload) {
            commit('setLoadingFinish', true)
            commit('setUsername', payload.username);
            commit('setEmail', payload.email);
            commit('setConfirmEmail', payload.confirmEmail);
            commit('setPassword', payload.password);
            commit('setConfirmPassword', payload.confirmPassword);
            commit('setPolicyCheck', payload.policyCheck);
            commit('setNotificationCheck', payload.notificationCheck);
            commit('setAnswers', payload.answers);

            commit('setQuestions', [])

            axios.post('/auth/finish-register', {
                codCli: state.clientCode,
                cpfCnpj: state.cpfCnpj,
                ageCheck: state.ageCheck,
                answers: state.answers,
                username: state.username,
                email: state.email,
                confirmEmail: state.confirmEmail,
                password: state.password,
                confirmPassword: state.confirmPassword,
                policyCheck: state.policyCheck,
                notificationCheck: state.notificationCheck,
            })
                .then((response) => {
                    showToast('success', response.data.details)

                    localStorage.setItem('isWaitingValidEmail', true);
                    router.push({name: 'ValidateToken'});
                })
                .catch(error => {
                    if (error.response.status !== 404) {
                        commit('setRestricted', true);
                        commit('setRestrictionTitle', error.response.data.message);
                        commit('setRestrictionMessage', error.response.data.details);
                    } else {
                        showToast('error', error.response.data.details);
                    }
                })
                .finally(() => {
                    commit('setLoadingFinish', false);
                });
        },
    },
});